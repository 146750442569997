import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useGetSet, useToggle } from 'react-use';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

import { useLazyGetPharmaciesQuery } from 'services/pharmacies/pharmacies';
import { GetPharmaciesReqProps, PharmacyItem } from 'services/pharmacies/pharmacies.types';

import GoogleMap from 'features/GoogleMap';
import Marker from 'features/GoogleMap/Marker';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import PharmacyActionsCard from 'widgets/Pharmacy/PharmacyActionsCard';
import PharmacyList from 'widgets/Pharmacy/PharmacyList';

import useAnalytics from 'hooks/useAnalytics';
import useWidth from 'hooks/useWidth';
import { LOS_ANGELES_COORDS, MAP_STYLES } from 'utils/constants';
import { distanceByCoords, handleRequestCatch } from 'utils/helpers';

import { getIcon } from './pharmacy.settings';
import { PharmacyProps } from './pharmacy.types';

import LocatorSvg from 'assets/images/map/locator.svg';

const render = (status: Status): ReactElement => {
  if (status === Status.FAILURE) return <>Error with map loading, please try again</>;
  return <Loader isVisible />;
};

const Pharmacy: React.FC<PharmacyProps> = ({
  accessToken,
  mailingAddress,
  onSelect,
  onDeselect,
  isLoading,
  userPharmacyID,
  onContinue,
  defaultCoords,
  isIntakeForm = false
}) => {
  const logEvent = useAnalytics();
  const { isMobile } = useWidth();
  const [isOpen, setIsOpen] = useToggle(true);
  const mapRef = useRef(null);
  const [lastSearchType, setLastSearchType] = useState('');
  const [showRedoButton, toggleRedoButton] = useState(false);
  const [shouldBound, setShouldBound] = useState(false);
  const [getPharmacy, { isFetching }] = useLazyGetPharmaciesQuery();
  const [selectedPharmacy, setSelectedPharmacy] = useGetSet<PharmacyItem | null>(null);
  const [typeOnPause, setTypeOnPause] = useState(false);
  const [search, setSearch] = useGetSet('');
  const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = useState(12); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(LOS_ANGELES_COORDS);
  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral | undefined>();
  const [pharmaciesList, setPharmaciesList] = useState<PharmacyItem[]>([]);
  const userPharmacy = pharmaciesList.find((p) => p.pharmacyId === userPharmacyID);
  const GOOGLE_MAP_WIDTH = mapRef?.current
    ? (mapRef?.current as unknown as HTMLDivElement).offsetWidth / 2
    : 260;

  const [searchBody, setSearchBody] = useGetSet<
    Omit<GetPharmaciesReqProps, 'accessToken' | 'zoom'>
  >({
    address: undefined,
    currentLatitude: '',
    currentLongitude: '',
    latitude: '',
    longitude: '',
    pixels: GOOGLE_MAP_WIDTH
  });

  const searchPharmacies = () => {
    const body = searchBody();
    if (search().length < 3 && !body.latitude && !body.longitude && !body.address) {
      return;
    }
    setTypeOnPause(false);
    getPharmacy({ accessToken, ...body, zoom })
      .unwrap()
      .then(({ data }) => {
        toggleRedoButton(false);
        setLastSearchType(body.address ? 'address' : '');
        if (body.address && data.pharmacies.length) {
          setShouldBound(true);
        }
        data.selectedLocation &&
          setCenter({
            lat: data.selectedLocation.latitude,
            lng: data.selectedLocation.longitude
          });
        setPharmaciesList(uniqBy(data.pharmacies, 'pharmacyId'));
        // setPharmaciesList(uniqBy([...pharmaciesList, ...data.pharmacies], 'pharmacyId'));
        if (!data.pharmacies.length) {
          setTypeOnPause(true);
        }
        setShouldBound(false);
      })
      .catch((e) => {
        toggleRedoButton(true);
        handleRequestCatch(e, 'Entered location not found');
      });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (typeOnPause) {
      setTypeOnPause(false);
    }
  };

  const debouncedHandleChange = useMemo(
    () =>
      debounce((v) => {
        setSearchBody(v);
        searchPharmacies();
      }, 2000),
    []
  );

  const setUsersDefaultPharmacyLocation = () => {
    const lat = defaultCoords?.lat || LOS_ANGELES_COORDS.lat;
    const lng = defaultCoords?.lng || LOS_ANGELES_COORDS.lng;
    setCenter({
      lat,
      lng
    });
    setSearchBody({
      currentLatitude: `${lat}`,
      currentLongitude: `${lng}`,
      latitude: `${lat}`,
      longitude: `${lng}`,
      pixels: GOOGLE_MAP_WIDTH
    });
  };

  const successCallback = (location: GeolocationPosition) => {
    setCenter({
      lat: location.coords.latitude,
      lng: location.coords.longitude
    });
    setSearchBody({
      currentLatitude: `${location.coords.latitude}`,
      currentLongitude: `${location.coords.longitude}`,
      latitude: `${location.coords.latitude}`,
      longitude: `${location.coords.longitude}`,
      pixels: (mapRef?.current as unknown as HTMLDivElement)?.offsetWidth
    });
    searchPharmacies();
  };

  const errorCallback = () => {
    setSearchBody({
      address: mailingAddress.zipCode || mailingAddress.city,
      currentLatitude: `${center.lat}`,
      currentLongitude: `${center.lng}`,
      latitude: `${center.lat}`,
      longitude: `${center.lng}`,
      pixels: GOOGLE_MAP_WIDTH
    });
    searchPharmacies();
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    setIsOpen(false);
    setClicks([...clicks, e.latLng!]);
  };

  const updateSearchBody = (m: google.maps.Map) => {
    toggleRedoButton(true);
    setSearchBody({
      currentLatitude: `${m.getCenter()!.toJSON().lat}`,
      currentLongitude: `${m.getCenter()!.toJSON().lng}`,
      latitude: `${m.getCenter()!.toJSON().lat}`,
      longitude: `${m.getCenter()!.toJSON().lng}`,
      pixels: GOOGLE_MAP_WIDTH
    });
  };

  const onIdle = (m: google.maps.Map) => {
    if (lastSearchType === 'address') {
      setLastSearchType('');
      return;
    }
    if (zoom !== m.getZoom()!) {
      updateSearchBody(m);
      setZoom(m.getZoom()!);
    } else if (
      (center.lat && Math.abs(center.lat - m.getCenter()!.toJSON().lat) > 0.01) ||
      Math.abs(center.lng - m.getCenter()!.toJSON().lng) > 0.01
    ) {
      setCenter(m.getCenter()!.toJSON());
      updateSearchBody(m);
    }
  };

  const handleClickSearch = () => {
    logEvent('pharmacies_redo_search_btn_click');
    searchPharmacies();
  };

  const handleClickPharmacy = (id: number) => {
    const newSelectedPharmacy =
      selectedPharmacy()?.pharmacyId === id
        ? selectedPharmacy() || null
        : pharmaciesList.find((p) => p.pharmacyId === id) || null;

    setSelectedPharmacy(newSelectedPharmacy);
    if (selectedPharmacy()?.pharmacyId === id) {
      setIsOpen(true);
    }
  };

  const handleChoosePharmacy = () => {
    logEvent('pharmacies_choose_btn_click');
    onSelect(selectedPharmacy()?.pharmacyId);
  };

  const handleDeselect = () => {
    logEvent('pharmacies_deselect_btn_click');
    onDeselect?.(selectedPharmacy()?.pharmacyId);
  };

  useEffect(() => {
    if (userPharmacyID) {
      setUsersDefaultPharmacyLocation();
      searchPharmacies();
    } else if (mailingAddress.address) {
      setSearchBody({
        address: `${mailingAddress.address}, ${mailingAddress.city}, ${mailingAddress.state}, ${mailingAddress.zipCode}`,
        currentLatitude: `${center.lat}`,
        currentLongitude: `${center.lng}`,
        latitude: `${center.lat}`,
        longitude: `${center.lng}`,
        pixels: GOOGLE_MAP_WIDTH
      });
      searchPharmacies();
    } else {
      navigator.geolocation?.getCurrentPosition((location) => {
        setUserLocation({
          lat: location.coords.latitude,
          lng: location.coords.longitude
        });
        if (!userPharmacyID) {
          successCallback(location);
        }
      }, errorCallback);
    }
  }, []);

  useEffect(() => {
    debouncedHandleChange({
      address: search(),
      currentLatitude: `${center.lat}`,
      currentLongitude: `${center.lng}`,
      pixels: GOOGLE_MAP_WIDTH
    });
  }, [search()]);

  useEffect(() => setSelectedPharmacy(userPharmacy || null), [userPharmacy]);

  return (
    <>
      <FadeWrapper
        className={classNames(
          'left-0 top-0 z-10 bg-white flex h-full overflow-hidden max-md:absolute max-md:w-full md:-m-8 md:mt-4',
          { 'border-t': !isIntakeForm }
        )}
      >
        <PharmacyList
          handleInputChange={handleInputChange}
          isFetching={isFetching}
          isOpen={isOpen}
          pharmaciesList={pharmaciesList}
          searchValue={search()}
          selectedPharmacyId={selectedPharmacy()?.pharmacyId}
          typeOnPause={typeOnPause}
          userLocation={userLocation}
          userPharmacy={userPharmacy}
          onClickPharmacy={(id) => {
            logEvent('pharmacies_pharmacy_item_click');
            handleClickPharmacy(id);
          }}
        />
        <div className="relative w-full" ref={mapRef}>
          {showRedoButton && (
            <Common.Button
              className="!absolute inset-x-0 top-20 z-10 mx-auto mb-4 md:top-4"
              color="white"
              disabled={isFetching}
              isLoading={isFetching}
              size="sm"
              style="pill"
              onClick={handleClickSearch}
            >
              Redo search in this area
            </Common.Button>
          )}
          <Wrapper
            apiKey={import.meta.env.VITE_GOOGLE_API_KEY || ''}
            language="en"
            region="US"
            render={render}
          >
            <GoogleMap
              center={center}
              mapPoints={pharmaciesList}
              shouldBound={shouldBound}
              style={{ height: '100%', width: '100%' }}
              styles={MAP_STYLES}
              zoom={zoom}
              onClick={onClick}
              onIdle={onIdle}
            >
              {userLocation && (
                <Marker
                  icon={LocatorSvg}
                  key="locator"
                  position={userLocation}
                  onClick={() => {}}
                />
              )}
              {pharmaciesList.map((pharmacy) => (
                <Marker
                  icon={getIcon(
                    pharmacy,
                    isOpen,
                    userPharmacy,
                    selectedPharmacy()?.pharmacyId,
                    isMobile
                  )}
                  key={pharmacy.pharmacyId}
                  label={{
                    className: `marker-label ${
                      selectedPharmacy()?.pharmacyId === pharmacy.pharmacyId &&
                      isOpen &&
                      'marker-label-selected'
                    }`,
                    text: pharmacy.name
                  }}
                  position={
                    pharmacy.latitude && pharmacy.longitude
                      ? { lat: pharmacy.latitude, lng: pharmacy.longitude }
                      : null
                  }
                  zIndex={pharmacy.pharmacyId === selectedPharmacy()?.pharmacyId ? 10 : 1}
                  onClick={() => {
                    logEvent('pharmacies_marker_click');
                    handleClickPharmacy(pharmacy.pharmacyId);
                  }}
                />
              ))}
            </GoogleMap>
          </Wrapper>
          {isOpen && !!selectedPharmacy() && (
            <PharmacyActionsCard
              distance={
                userLocation && selectedPharmacy()?.latitude && selectedPharmacy()?.longitude
                  ? distanceByCoords(
                      Number(selectedPharmacy()!.latitude),
                      Number(selectedPharmacy()!.longitude),
                      userLocation.lat,
                      userLocation.lng
                    )
                  : undefined
              }
              isLoading={isLoading}
              selectedPharmacy={selectedPharmacy()!}
              userPharmacy={userPharmacy}
              onChoose={handleChoosePharmacy}
              onContinue={onContinue}
              onDeselect={handleDeselect}
            />
          )}
        </div>
      </FadeWrapper>
    </>
  );
};

export default Pharmacy;
