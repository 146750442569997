import { useNavigate } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';

import { useGetProductsQuery, useGetShopOrdersQuery } from 'services/shop/shop';

import { selectMigrateToBundlePlanStatus } from 'store';

import FeaturedProduct from 'pages/shop/Home/components/FeaturedProduct';
import Tracker from 'pages/shop/Home/components/Tracker';

import ProductsList from 'features/ProductsList';
import Loader from 'shared/Loader';

import { useAppSelector } from 'hooks';
import { FeatureFlag, PathName, ShippingStatuses } from 'utils/enums';

const Home = () => {
  const navigate = useNavigate();
  const isEnableMigrationToBundlePlan = useFlag(FeatureFlag.MigrateToBundlePlan);

  const isDisabledMigrateToBundlePlan = useAppSelector(selectMigrateToBundlePlanStatus);
  const { data: products, isFetching } = useGetProductsQuery();
  const { data: labs, isFetching: isFetchingLabs } = useGetProductsQuery({ category: 'labs' });
  const { data: shopOrders } = useGetShopOrdersQuery();

  const shopOrderDetails = shopOrders?.data.filter(
    (order) => order.shippingStatus !== ShippingStatuses.Delivered
  );

  return (
    <div className="flex h-full flex-col gap-2.5">
      <Loader isVisible={isFetching || isFetchingLabs} />
      {!!shopOrderDetails?.length && <Tracker orders={shopOrderDetails} />}
      {!isDisabledMigrateToBundlePlan && isEnableMigrationToBundlePlan && (
        <>
          <h3 className="pt-3 text-mBase font-semibold" data-testid="featured_header">
            Featured
          </h3>
          <div className="overflow-hidden rounded-xl shadow-lg md:mb-6">
            <FeaturedProduct onUpgradePlan={() => navigate(PathName.MigrateToBundlePlan)} />
          </div>
        </>
      )}
      {!!products?.data.length && (
        <>
          <h3 className="pt-3 text-mBase font-semibold" data-testid="devices_header">
            Devices
          </h3>
          <ProductsList products={products.data} bgWhite />
        </>
      )}
      {!!labs?.data.length && (
        <>
          <h3 className="pt-3 text-mBase font-semibold" data-testid="labs_header">
            Order labs
          </h3>
          <ProductsList products={labs.data} />
        </>
      )}
    </div>
  );
};

export default Home;
