import { JSX, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';
import { AnimatePresence } from 'framer-motion';

import { selectOrchestrate } from 'store';

import MedicaidWaiver from 'pages/SignUp/components/MedicaidWaiver';

import { MixedSignupStepProps } from 'containers/SignUp/Content/content.types';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import CheckboxGroup from 'shared/CheckboxGroup';

import { useAppSelector } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';

import Advantage from '../parts/Advantage';
import BackToMainWebsiteButton from '../parts/BackToMainWebsiteButton';
import Heading from '../parts/Heading';

import { InsuranceTypes } from 'models/insurance.types';

const ITEMS: {
  label: JSX.Element;
  value: InsuranceTypes;
}[] = [
  {
    label: (
      <>
        <p className="font-bold">Employer / commercial</p>
        <p className="text-mSm text-gray md:text-sm">IE: Aetna, Humana, etc</p>
      </>
    ),
    value: 'employer_commercial'
  },
  {
    label: (
      <>
        <p className="font-bold">Medicare</p>
        <p className="text-mSm text-gray md:text-sm">Government plan</p>
      </>
    ),
    value: 'medicare'
  },
  {
    label: (
      <>
        <p className="font-bold">Medicaid</p>
        <p className="text-mSm text-gray md:text-sm">Government plan</p>
      </>
    ),
    value: 'medicaid'
  },
  {
    label: (
      <>
        <p className="font-bold">Veterans Affairs (VA) / TRICARE</p>
        <p className="text-mSm text-gray md:text-sm">
          ie: Humana Military, Health Net Federal Services, etc
        </p>
      </>
    ),
    value: 'veterans_tricare'
  },
  {
    label: (
      <>
        <p className="font-bold">Uninsured</p>
        <p className="text-mSm text-gray md:text-sm">I do not have Medical Insurance</p>
      </>
    ),
    value: 'uninsured'
  }
];

const InsuranceTypeExtended: React.FC<{
  onCancel: () => void;
  onComplete: (v?: string[]) => void;
  selectedFlow: MixedSignupStepProps['selectedFlow'];
}> = ({ onComplete, onCancel, selectedFlow }) => {
  const [params, setParams] = useSearchParams();

  const timer = useRef<ReturnType<typeof setTimeout>>(undefined);
  const { send, isLoading } = useSubmitOrchestrateForm();
  const {
    user: { email }
  } = useAppSelector(selectOrchestrate);

  const step = params.get('step') as 'waiver' | 'alt' | 'fail-insurance' | null;

  const [selectedItems, setSelectedItems] = useState<InsuranceTypes[]>([]);

  const handleSelect = (v: string | number) => {
    const patchedValue = String(v) as InsuranceTypes;
    let newArray: InsuranceTypes[] = [];
    if (patchedValue === 'uninsured') {
      if (!selectedItems.includes('uninsured')) {
        newArray = [patchedValue];
      }
    } else {
      newArray = selectedItems.includes(patchedValue)
        ? selectedItems.filter((i) => i !== patchedValue && i !== 'uninsured')
        : [...selectedItems, patchedValue].filter((i) => i !== 'uninsured');
    }

    setSelectedItems(newArray);
  };

  const handleSubmit = () => {
    if (selectedItems.includes('medicare')) {
      setParams((params) => {
        params.set('hideProgress', 'true');
        params.set('step', 'fail-insurance');
        params.set('a', 'medicare');
        return params;
      });
      return;
    }
    send('mif_qa', [{ answer: selectedItems, question: 'insurance_types' }], () => {
      if (!selectedItems.includes('uninsured')) {
        setParams((params) => {
          params.set('step', 'waiver');
          return params;
        });
      } else {
        setParams(
          (params) => {
            params.set('step', 'alt');
            params.set('hideProgress', 'true');
            return params;
          },
          { replace: true }
        );
        timer.current = setTimeout(() => {
          setParams(
            (params) => {
              params.delete('hideProgress');
              params.delete('step');
              return params;
            },
            { replace: true }
          );
          onComplete(selectedItems);
        }, 2700);
      }
    });
  };

  const signWaiver = () => {
    send(
      'agreements',
      {
        medicaidWaiverAgreed: true
      },
      () => {
        setParams(
          (params) => {
            params.set('step', 'alt');
            params.set('hideProgress', 'true');
            return params;
          },
          { replace: true }
        );

        timer.current = setTimeout(() => {
          setParams(
            (params) => {
              params.delete('hideProgress');
              params.set('step', 'waiver');
              return params;
            },
            { replace: true }
          );
          onComplete(selectedItems);
        }, 2700);
      }
    );
  };

  const handleProspect = () => {
    const answer = params.get('a');
    if (answer && !!email) {
      send(
        'mif_qa',
        [{ answer, question: 'insurance_types' }],
        () => {
          onCancel();
        },
        'PROSPECT'
      );
    } else {
      onCancel();
    }
  };

  const isErrorState = params.get('step') === 'fail-insurance';

  useEffect(() => {
    setParams(
      (params) => {
        if (params.get('hideProgress')) {
          !isErrorState && params.delete('hideProgress');
        }
        return params;
      },
      { replace: true }
    );
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <AnimatePresence mode="wait">
      {isErrorState && (
        <FadeWrapper className="flex h-full flex-col gap-6 text-primary-700" key="forbidden">
          <Heading
            category="Plan"
            subtitle={
              <span>
                At this time, those with any form of government healthcare coverage (including
                programs such as <span className="font-bold">Medicare</span> or{' '}
                <span className="font-bold">TRICARE</span>), whether primary or secondary or
                government-related coverage such as Medicare Supplement Plans, are not eligible for
                LifeMD GLP-1 weight loss programs.
              </span>
            }
            title="Sorry, we do not currently accept Medicare at this time for GLP-1 treatment."
          />
          <Common.Alert type="info" colorableBackground>
            <span className="text-base">
              These plans typically do not cover the type of medication LifeMD programs may
              prescribe. Unfortunately, this means that those on{' '}
              <span className="font-bold">Medicare</span> or eligible for{' '}
              <span className="font-bold">Medicare</span> cannot join the LifeMD GLP-1 weight loss
              programs.
            </span>
          </Common.Alert>
          <BackToMainWebsiteButton loading={isLoading} onClick={handleProspect} />
        </FadeWrapper>
      )}
      {!step && (
        <FadeWrapper className="flex h-full flex-col gap-6" key="initial">
          <Heading category="Plan" title="Which type of health insurance do you have?" />
          <div>
            <CheckboxGroup items={ITEMS} selectedItems={selectedItems} onSelect={handleSelect} />
          </div>
          <Common.Button
            className="max-md:mt-auto md:mx-auto"
            color="blue"
            disabled={selectedItems.length === 0}
            isLoading={isLoading}
            fullWidthOnMobile
            onClick={handleSubmit}
          >
            Continue
          </Common.Button>
        </FadeWrapper>
      )}
      {step === 'waiver' && (
        <FadeWrapper className="flex h-full flex-col gap-6" key="waiver">
          <MedicaidWaiver loading={isLoading} onContinue={signWaiver} />
        </FadeWrapper>
      )}
      {step === 'alt' && (
        <FadeWrapper className="flex h-full flex-col place-items-center p-6" key="alt">
          <Advantage selectedFlow={selectedFlow} />
        </FadeWrapper>
      )}
    </AnimatePresence>
  );
};

export default InsuranceTypeExtended;
