import { selectUser } from 'store';

import KrogerMap from 'widgets/KrogerMap';
import { getWebsiteURLByBrand } from 'widgets/KrogerMap/krogerMap.settings';

import { useAppSelector } from 'hooks';

const KrogerLocations = () => {
  const { zipCode, city, address, state } = useAppSelector(selectUser);

  return (
    <KrogerMap
      isLoading={false}
      mailingAddress={{
        address,
        city,
        state,
        zipCode
      }}
      onContinue={() => {}}
      onSelect={(shop) =>
        window.open(getWebsiteURLByBrand(shop?.brand), '_blank', 'noopener noreferrer')
      }
    />
  );
};

export default KrogerLocations;
