import RangeSection from '../RangeSection';

import { getRangeSections } from './rangeScale.settings';
import { RangeScaleProps } from './rangeScale.types';

const RangeScale: React.FC<RangeScaleProps> = ({ referenceMax, referenceMin, value }) => {
  const rangeSections = getRangeSections(value, referenceMax, referenceMin);

  return (
    <div
      className={`grid gap-px ${referenceMax && referenceMin ? 'grid-cols-3' : 'grid-cols-2'}`}
      data-testid="lab_result_metric_range"
    >
      {rangeSections?.map((rangeSectionsProps) => (
        <RangeSection key={rangeSectionsProps.label} {...rangeSectionsProps} />
      ))}
    </div>
  );
};

export default RangeScale;
