import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import { CheckMedicationCoverageResProps } from './patients.types';

export const patientsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    checkMedicationCoverage: build.query<CheckMedicationCoverageResProps, string>({
      query: (accessToken) => ({
        headers: {
          Authorization: accessToken
        },
        url: '/patients/benefits'
      })
    })
  }),
  reducerPath: 'patientsApi',
  tagTypes: ['Patients']
});

export const { useLazyCheckMedicationCoverageQuery } = patientsApi;
