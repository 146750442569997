import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';

import { useLazyGetSuggestedAddressesQuery } from 'services/lookup/lookup';
import { useLazyGetMyAccountQuery, useUpdateMyAccountMutation } from 'services/myAccount/myAccount';

import { selectStates, selectUser } from 'store';
import { setUser } from 'store/user/userSlice';

import SuggestedAddress from 'modals/SuggestedAddress';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Address from 'shared/form/Address';

import { useAppDispatch, useAppSelector } from 'hooks';
import { FeatureFlag } from 'utils/enums';
import {
  buildAddress,
  buildQueryForAddress,
  compareSuggestedAddress,
  handleRequestCatch
} from 'utils/helpers';

import { MailingAddress } from 'models/user.types';

import { Props } from './completeAddress.types';

const CompleteAddress: React.FC<Props> = ({
  moveToStep,
  shouldRefetchAccountData = true,
  callback,
  loading = false
}) => {
  useTitle('LifeMD - Complete address');

  const dispatch = useAppDispatch();
  const isSuggestedAddressEnabled = useFlag(FeatureFlag.SuggestedAddress);

  const [suggestedAddress, setSuggestedAddress] = useState<MailingAddress | undefined>(undefined);
  const [isOpen, toggle] = useToggle(false);

  const [getMyAccount, { isFetching: isGettingAccount }] = useLazyGetMyAccountQuery();
  const [updateMyAccount, { isLoading }] = useUpdateMyAccountMutation();
  const [getSuggestions, { isFetching }] = useLazyGetSuggestedAddressesQuery();
  const { address, city, zipCode, state } = useAppSelector(selectUser);
  const states = useAppSelector(selectStates);
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid },
    trigger
  } = useForm<MailingAddress>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const onUpdate = (values: MailingAddress) => {
    toggle(false);
    updateMyAccount({ mailingAddress: values })
      .unwrap()
      .then(async () => {
        if (shouldRefetchAccountData) {
          getMyAccount()
            .unwrap()
            .then(() => {
              moveToStep('next');
            });
        } else {
          dispatch(setUser({ ...values }));
          if (callback) {
            await callback();
          }
          moveToStep('next');
        }
      })
      .catch(handleRequestCatch);
  };

  const onSubmit = (values: MailingAddress) => {
    if (!isSuggestedAddressEnabled) {
      isValid && onUpdate(values);
    } else {
      if (isValid) {
        getSuggestions(buildQueryForAddress(values, states))
          .unwrap()
          .then(({ data: { suggestions } }) => {
            const isValidAddressIncluded = suggestions.some((addr) =>
              compareSuggestedAddress(values, buildAddress(addr, states))
            );
            if (isValidAddressIncluded) {
              onUpdate(values);
            } else {
              if (suggestions.length > 0) {
                setSuggestedAddress(buildAddress(suggestions[0], states));
              }
              toggle();
            }
          })
          .catch(() => onUpdate(values));
      }
    }
  };

  useEffect(() => {
    reset({
      address,
      city,
      state,
      zipCode
    });
  }, []);

  return (
    <FadeWrapper className="flex h-full flex-col">
      <SuggestedAddress
        currentAddress={getValues()}
        isOpen={isOpen}
        loading={isGettingAccount || isLoading}
        suggestedAddress={suggestedAddress}
        onClose={() => {
          toggle(false);
          setSuggestedAddress(undefined);
        }}
        onConfirm={onUpdate}
      />
      <h1 className="mb-6 text-m2xl font-bold text-primary-700 md:text-center md:text-2xl">
        Complete your profile
      </h1>
      <form
        className="relative flex flex-col rounded-2xl max-md:h-full"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset className="flex flex-col gap-3">
          <h2 className="font-bold text-primary-700">Enter your place of residence</h2>
          <Address control={control} setValue={setValue} trigger={trigger} />
        </fieldset>
        <div className="relative mt-auto self-center">
          <Common.Button
            className="mt-4 self-center md:mt-10"
            color="blue"
            isLoading={isLoading || isFetching || isGettingAccount || loading}
            fullWidthOnMobile
          >
            Continue
          </Common.Button>
        </div>
      </form>
    </FadeWrapper>
  );
};

export default CompleteAddress;
