import { Common } from '@thecvlb/design-system';

import { AltSignupStepProps } from 'containers/SignUp/Content/content.types';
import StaticReviewsList from 'widgets/reviews/StaticReviewsList';

import { FlowTypes } from 'utils/enums';

import { DUMMY_REVIEWS_WM } from '../SelectPlan/selectPlan.settings';

import iPhone from 'assets/images/signUp/iPhone.png';

const WeightLoseWithoutUncertainty: React.FC<AltSignupStepProps> = ({
  selectedFlow,
  onContinue
}) => {
  return (
    <div className="mx-auto flex max-w-[500px] flex-col items-center gap-6">
      <div className="flex flex-col gap-2 text-center md:gap-6">
        <img
          alt="Weight loss without uncertainty."
          className="mx-auto w-[188px] md:w-[256px]"
          src={iPhone}
        />
        <div>
          {selectedFlow === FlowTypes.WeightManagementFlowOptavia && (
            <h3 className="mb-2 text-center text-mBase font-bold text-primary-700">
              LifeMD & Optavia
            </h3>
          )}
          <h2 className="wm-signup-title" data-testid="header">
            Weight loss without uncertainty.
          </h2>
        </div>
        <p className="text-primary-700">
          We’re committed to providing transparency throughout your entire journey, from the cost of
          your medication to the status of your prescription to the coverage provided by your
          insurance.
        </p>
      </div>
      <div className="rounded-lg bg-secondary-100 p-4 text-primary">
        <span className="font-bold">The cost of GLP-1 medication is not included</span> in the
        LifeMD Weight Management Program. However, LifeMD Insurance Assistance will work hands-on to
        get you the absolute lowest price for your medication.
      </div>
      <div className="order-3 max-md:w-full md:order-4 md:max-w-[1240px]">
        <StaticReviewsList reviews={DUMMY_REVIEWS_WM} />
      </div>
      <Common.Button
        className="order-4 md:order-3"
        color="blue"
        fullWidthOnMobile
        onClick={onContinue}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default WeightLoseWithoutUncertainty;
