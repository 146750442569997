import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';

import { useLazyGetReportQuery, useLazyGetReportResultsQuery } from 'services/reports/reports';
import { GetReportResProps } from 'services/reports/reports.types';

import LabsResultMetricCard from 'features/LabsResultMetricCard';
import MyChartTitle from 'features/MyChartTitle';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';

import useWidth from 'hooks/useWidth';
import { PathName } from 'utils/enums';

import CircleChart from '../CircleChart';

const LabResults = () => {
  const navigate = useNavigate();
  useTitle('LifeMD - Labs & Imaging');
  const showScheduleAppointment = !useFlag('disable-schedule-appt-on-lab-result');
  const { isMobile } = useWidth();
  const { id: reportId } = useParams();
  const [getReportResults, { data, isFetching }] = useLazyGetReportResultsQuery();

  const [getReportQuery, { isFetching: isDownloadFetching, isLoading: isDownloadLoading }] =
    useLazyGetReportQuery();

  const report = data?.data;
  const rangeCounts = report?.results?.reduce(
    (acc, item) => {
      if (item.isAbnormal) {
        acc.outOfRange += 1;
      } else {
        acc.inRange += 1;
      }
      return acc;
    },
    { inRange: 0, outOfRange: 0 }
  );

  useEffect(() => {
    if (reportId) {
      getReportResults({ reportId });
    }
  }, [reportId]);

  const handleDownloadReport = () => {
    reportId &&
      getReportQuery({ reportId })
        .unwrap()
        .then((res: GetReportResProps) => {
          fileDownload(res, `${report?.title || report?.reportType || 'Report'}.pdf`);
        });
  };
  return (
    <FadeWrapper className="flex flex-col md:p-8">
      <Loader isVisible={isFetching} />
      <MyChartTitle icon="experiment" text="Labs & imaging" />
      <div className="flex flex-col gap-6">
        <div className="flex justify-between">
          <Common.Button
            className="pl-0 text-mLg md:text-mBase"
            preIcon="arrow-left"
            style="text-only"
            onClick={() => navigate(PathName.Labs)}
          >
            {report?.title || report?.reportType || 'Back'}
          </Common.Button>
          <Common.Button
            className="hidden md:flex"
            color="white-alt"
            dataTestId="download_btn"
            isLoading={isDownloadFetching || isDownloadLoading}
            preIcon="download"
            size="sm"
            onClick={handleDownloadReport}
          >
            Download results
          </Common.Button>
        </div>
        <div className="flex flex-col gap-4 rounded-2xl p-6 md:flex-row-reverse md:justify-end md:bg-blue-50">
          <div className="flex flex-col justify-center text-mBase font-medium text-gray-500 md:text-base">
            <p>Completed {dayjs(report?.signedDate).format('MMM DD, YYYY')}</p>
            <p>
              We collected {report?.results?.length} biomarkers for your lab panel.{' '}
              <span className="text-gray-700">{rangeCounts?.inRange} were in-range</span>, and{' '}
              <span className="text-gray-700">{rangeCounts?.outOfRange} were out of range</span>.
            </p>
          </div>
          {report?.results?.length && (
            <div className="flex gap-4">
              <CircleChart
                inRangeCount={rangeCounts?.inRange ?? 0}
                outOfRangeCount={rangeCounts?.outOfRange ?? 0}
                totalCount={report?.results?.length}
              />
              <div className="flex flex-col justify-center md:w-[123px]">
                <div className="flex items-center gap-2">
                  <div className="w-2">{rangeCounts?.inRange}</div>{' '}
                  <div className="size-[8px] rounded-full bg-blue-300" /> In range
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-2">{rangeCounts?.outOfRange}</div>
                  <div className="size-[8px] rounded-full bg-yellow-500" /> Out of range
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {report?.results?.map((result, idx) => (
            <LabsResultMetricCard {...result} key={idx} status={'in-range'} />
          ))}
        </div>
        <div className="flex items-end justify-end">
          <Common.Button
            className="md:hidden"
            color="white-alt"
            dataTestId="download_btn"
            isLoading={isDownloadFetching || isDownloadLoading}
            preIcon="download"
            size="md"
            onClick={handleDownloadReport}
          >
            Download results
          </Common.Button>
        </div>
        {showScheduleAppointment && (
          <section>
            <h2 className="mb-6 text-mLg font-bold md:text-xl">Take action</h2>
            <div className="flex flex-col gap-6 rounded-2xl bg-white p-6 md:p-0">
              <div>
                <p className="font-bold">Follow-up with a provider</p>
                <p className="font-medium text-gray">
                  Meet with a provider to discuss your lab results and create an action plan.
                </p>
              </div>
              <Common.Button
                color="blue"
                size={isMobile ? 'lg' : 'md'}
                fullWidthOnMobile
                onClick={() => navigate(PathName.CreateAppointment)}
              >
                Schedule appointment
              </Common.Button>
            </div>
          </section>
        )}
      </div>
    </FadeWrapper>
  );
};

export default LabResults;
