export const getOffset = (v: number) => {
  switch (true) {
    case v < 5:
      return 'left-0';
    case v < 10:
      return 'left-[5%]';
    case v < 15:
      return 'left-[13%]';
    case v < 18:
      return 'left-[15%]';
    case v < 22:
      return 'left-[18%]';
    case v < 25:
      return 'left-[22%]';
    case v < 27.5:
      return 'left-[24%]';
    case v < 29:
      return 'left-[27%]';
    case v < 32:
      return 'left-[29%]';
    case v < 38:
      return 'left-[33%]';
    case v < 40:
      return 'left-[36%]';
    case v < 44:
      return 'left-[40%]';
    case v < 47:
      return 'left-[44%]';
    case v < 53:
      return 'left-[47%]';
    case v < 56:
      return 'left-[53%]';
    case v < 60:
      return 'left-[56%]';
    case v < 65:
      return 'left-[60%]';
    case v < 68:
      return 'left-[65%]';
    case v < 75:
      return 'left-[68%]';
    case v < 80:
      return 'left-[75%]';
    case v >= 80 && v < 95:
      return 'left-[80%]';
    case v >= 95:
      return 'right-0';
  }
};
