import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetSet } from 'react-use';

import {
  useAddAppointmentMutation,
  useUploadFilesMutation
} from 'services/appointments/appointments';
import { useGetMembershipPlansQuery } from 'services/lookup/lookup';
import { useLazyGetMyAccountQuery, useUpdateMyAccountMutation } from 'services/myAccount/myAccount';

import { selectNewAppointment, selectUser } from 'store';
import {
  clearNewAppointment,
  setMembershipData,
  setNewAppointment
} from 'store/appointments/appointmentsSlice';
import { clearAppointmentMif } from 'store/mif/mifSlice';
import { setUser } from 'store/user/userSlice';

import { Props } from 'containers/CreateAppointment/Content/content.types';
import {
  buildBodyForApptSchedule,
  defineFlowSteps
} from 'containers/CreateAppointment/createAppointment.settings';
import {
  CREATE_APPOINTMENT_STEPS,
  FillAppointmentDataProps,
  StepName
} from 'containers/CreateAppointment/createAppointment.types';
import { notifyError } from 'shared/Toast/Toast';

import { WM_INSURANCE_VALID_ONBOARDING_PRICE_POINTS } from 'constants/onboarding';
import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import useWeightManagement from './useWeightManagement';

export const useCreateAppointment = () => {
  const [getMyAccount, { isFetching }] = useLazyGetMyAccountQuery();
  const { isWeightManagement } = useWeightManagement();

  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  const { activePlanCode, isUnlimitedPlan, activePricePoint } = useAppSelector(selectUser);
  const { membershipData, ...rest } = useAppSelector(selectNewAppointment) ?? {};

  const [updateMyAccount, { isLoading: isLoadingUpdateUser }] = useUpdateMyAccountMutation();
  const [addAppointment, { isLoading: isLoadingAddAppointment }] = useAddAppointmentMutation();
  const { data: plans, isFetching: isFetchingPlans } = useGetMembershipPlansQuery();
  const [uploadFiles] = useUploadFilesMutation();

  const [isBackAnimation, toggleBackAnimation] = useGetSet(false);
  const [steps, setSteps] = useGetSet([...CREATE_APPOINTMENT_STEPS]);

  const predefinedCategory = useQuery().get('c');
  const currentStep = (query.get('s') ?? '') as StepName;
  // const srcFromQuery = useQuery().get('src'); // hide for now, since we don't use sc and webview. If we'll use SC, we should filter steps on useEffect
  const dateFromQuery = useQuery().get('date') || '';

  const existingPlan = plans?.data.find((p) => p.planCode === activePlanCode);
  const selectedPlan = plans?.data.find((p) => p._id === membershipData?.newPlanID);

  const isInsurancePatient =
    !!activePricePoint && WM_INSURANCE_VALID_ONBOARDING_PRICE_POINTS.includes(activePricePoint);

  const exitFlow = () => {
    navigate(PathName.Dashboard);
    dispatch(clearNewAppointment());
  };

  const scheduleAppointment = () => {
    const body = buildBodyForApptSchedule({
      ...rest,
      callType: 'video',
      isWeightManagementAppointment: false
    });
    const scheduleAppointmentThen = () => {
      sessionStorage.setItem('appointmentIsScheduled', 'true');
      dispatch(clearAppointmentMif());
      moveToStep('confirmation');
      dispatch(setNewAppointment({ status: 'created' }));
    };

    addAppointment(body)
      .unwrap()
      .then(({ data }) => {
        dispatch(setNewAppointment({ _id: data._id }));
        if (rest.files?.length && rest.uploadRequired) {
          const formData = new FormData();
          rest.files.forEach((file: File) => {
            formData.append('appointmentImages', file);
          });
          uploadFiles({
            appointmentId: data._id,
            body: formData
          })
            .unwrap()
            .catch(() => {
              toast.warn(
                'Appointment is scheduled successfully but there is an error with the uploading of the files'
              );
            })
            .finally(scheduleAppointmentThen);
        } else {
          scheduleAppointmentThen();
        }
      })
      .catch((e) => {
        handleRequestCatch(e);
        moveToStep('date-time');
      });
  };

  const handleSelectTime = () => {
    if (!isInsurancePatient && isUnlimitedPlan) {
      scheduleAppointment();
    } else {
      moveToStep('next');
    }
  };

  const handleUpgradePlan = () => {
    const isChangingPlan =
      (!!membershipData.newPlanID && membershipData.newPlanID !== existingPlan?._id) ||
      (!!membershipData.newPP && membershipData.newPP.planPricePointId !== activePricePoint);

    if (!isChangingPlan) {
      return scheduleAppointment();
    }
    updateMyAccount({
      planId: membershipData.newPlanID,
      ...(!!membershipData.newPP && { planPricePointId: membershipData.newPP.planPricePointId })
    })
      .unwrap()
      .then(() => {
        dispatch(
          setUser({
            activePricePoint: membershipData.newPP?.planPricePointId,
            activePlanId: selectedPlan?._id,
            isUnlimitedPlan: !!selectedPlan?.isUnlimitedPlan
          })
        );
        scheduleAppointment();
      });
  };

  const moveToStep: Props['moveToStep'] = (type, extraSearch = ''): void | Promise<void> => {
    const additionalSearch = extraSearch ? '&' + extraSearch : '';
    const currentStepIndex = steps().indexOf(currentStep);
    if (type === 'prev') {
      toggleBackAnimation(true);
      return navigate(-1);
    } else if (type === 'next') {
      toggleBackAnimation(false);
      let nextStep = steps()[currentStepIndex + 1];
      return nextStep ? navigate({ search: `s=${nextStep}${additionalSearch}` }) : exitFlow();
    } else {
      if (!steps().includes(type)) {
        toggleBackAnimation(true);
        notifyError('Something went wrong, please try again');
        return navigate({ search: `s=${steps()[0]}` }, { replace: true });
      }
      toggleBackAnimation(false);
      navigate(
        { search: `s=${type}${additionalSearch}` },
        {
          replace: type === 'confirmation'
        }
      );
    }
  };

  const onInit = () => {
    getMyAccount()
      .unwrap()
      .then(() => {
        if (
          (currentStep !== 'confirmation' && rest.status === 'created') ||
          currentStep === 'intro'
        ) {
          dispatch(clearNewAppointment());
        }
        if (predefinedCategory) {
          dispatch(setNewAppointment({ displayName: predefinedCategory }));
        }
        if (dateFromQuery) {
          dispatch(setNewAppointment({ initialDate: dateFromQuery }));
        }
        const filteredSteps: StepName[] = defineFlowSteps(CREATE_APPOINTMENT_STEPS, {
          isUnlimitedPlan,
          isInsurancePatient
        });
        setSteps(filteredSteps);
        if (!currentStep || !filteredSteps.includes(currentStep)) {
          navigate({ search: `s=${filteredSteps[0]}` }, { replace: true });
          dispatch(clearNewAppointment());
        }
        // since we can't store selected files locally and don't send them to the server immediately,
        // we have to check if user lost his files during refresh the page and navigate him to the files step
        const checkIfHaveBrokenFiles = () =>
          rest.files?.length &&
          rest.uploadRequired &&
          rest.files.some((file) => !(file instanceof File));

        if (checkIfHaveBrokenFiles()) {
          dispatch(
            setNewAppointment({
              files: rest?.files?.filter((file) => file instanceof File)
            })
          );
          moveToStep('files');
        }
      });
  };

  const fillAppointmentData = ({ step, data }: FillAppointmentDataProps) => {
    if (step === 'membership') {
      dispatch(setMembershipData(data));
    } else {
      dispatch(setNewAppointment(data));
    }
    if (step === 'category') {
      if (isWeightManagement && !!data.mifCode) {
        navigate(
          PathName.AppointmentMif +
            `/${data.mifCode}?redirectStep=${data.uploadRequired ? 'files' : 'date-time'}`
        );
      } else {
        moveToStep(data.uploadRequired ? 'files' : 'date-time');
      }
    }
  };

  useEffect(onInit, []);

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
    if (rest.status === 'created' && currentStep !== 'confirmation') {
      dispatch(clearNewAppointment());
      navigate(PathName.Appointments, { replace: true });
    }
    if (!currentStep) {
      navigate({ search: 's=intro' }, { replace: true });
    }
  }, [currentStep]);

  return {
    selectedPlan,
    currentStep,
    isFetching: isFetching || isFetchingPlans,
    loading: isLoadingUpdateUser || isLoadingAddAppointment || isFetching,
    moveToStep,
    steps: steps(),
    exitFlow,
    isBackAnimation: isBackAnimation(),
    currentPlan: existingPlan,
    fillAppointmentData,
    handleSelectTime,
    handleUpgradePlan
  };
};
