import { TouchEvent, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { ItemCard } from 'widgets/KrogerMap/components';

import useAnalytics from 'hooks/useAnalytics';

import { ItemListProps } from './itemsList.types';

import KrogerLogo from 'assets/images/kroger/kroger-locator-logo.svg?react';

const ItemsList: React.FC<ItemListProps> = ({
  handleInputChange,
  shopsList,
  onClickPharmacy,
  typeOnPause,
  isOpen,
  isFetching,
  searchValue,
  selectedPharmacyId,
  displayKrogerPreview = false
}) => {
  const [isOpenList, setOpenList] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isOpenKrogerPreview, toggleIsOpenKrogerPreview] = useToggle(displayKrogerPreview);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);
  const logEvent = useAnalytics();

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    const touch = touchStart - e.targetTouches[0].clientY;
    if (
      childRef.current &&
      childRef.current.contains(e.target as Node) &&
      childRef.current.scrollTop > 0
    ) {
      return;
    }
    setTouchEnd(touch);
  };
  const handleTouchEnd = () => {
    setOpenList(touchEnd > -50);
    setTouchStart(0);
  };

  const onFindStore = () => {
    logEvent('wm_kroger_find_store_btn_click');
    toggleIsOpenKrogerPreview();
  };

  return (
    <>
      <div
        className={classNames(
          'inset-y-8 z-10 flex w-full left-8 max-w-[350px] flex-col justify-between gap-6 rounded-xl bg-white transition-all duration-200 md:absolute md:p-8',
          { 'md:ml-[-374px] max-md:mt-[-224px]': !isOpenKrogerPreview }
        )}
      >
        <div className="flex flex-col gap-2">
          <KrogerLogo />
          <h2 className="text-xl font-semibold">Get started on healthy eating</h2>
          <p className="text-lg">
            Find a store near you, and shop curated lists for healthy eating on a GLP-1.
          </p>
        </div>
        <Common.Button
          className="w-full justify-center"
          color="white-alt"
          postIcon="arrow-alt-right"
          size="lg"
          onClick={onFindStore}
        >
          Find a store near me
        </Common.Button>
      </div>
      <div
        className={classNames(
          'pointer-events-none z-20 size-full flex-none absolute md:bg-white md:max-w-[350px]',
          isOpenKrogerPreview ? 'left-[-350px]' : 'left-0 animate-[shake_0.6s_ease-in-out]'
        )}
      >
        <div className="pointer-events-auto relative z-10 m-4">
          <Common.SearchInput
            className="rounded-full shadow"
            disabled={isFetching}
            placeholder="Search by name, address, ZIP..."
            value={searchValue}
            onChange={handleInputChange}
          />
        </div>
        <div
          className={classNames(
            'pointer-events-auto flex w-full md:h-[calc(100%_-_69px)] flex-col bg-white duration-300 ease-in-out max-md:absolute max-md:shadow md:rounded-xl',
            {
              'max-md:hidden': isOpen
            },
            isOpenList
              ? 'top-0 max-md:pt-[28px] h-full'
              : 'top-[calc(100%_-_235px)] max-md:rounded-t-3xl'
          )}
          ref={parentRef}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
        >
          <div className="mx-auto mb-8 mt-4 h-1.5 w-16 flex-none rounded-full bg-gray-200 md:hidden" />
          <div
            className={classNames('h-full divide-y md:overflow-auto', {
              'overflow-auto': isOpenList
            })}
            ref={childRef}
          >
            {shopsList.length
              ? shopsList.map((shop) => (
                  <ItemCard
                    distance={shop.distance}
                    isSelected={selectedPharmacyId === shop._id}
                    key={shop._id}
                    shop={shop}
                    onClick={onClickPharmacy}
                  />
                ))
              : !isFetching &&
                typeOnPause && (
                  <span className="mt-[52px] block p-2 text-center text-gray md:mt-auto">
                    No results...
                  </span>
                )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemsList;
