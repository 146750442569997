import { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { useCheckFreeAppointmentsMutation } from 'services/appointments/appointments';
import {
  useGetCreditBalanceQuery,
  useGetCreditCardInformationQuery,
  useLazyPreviewChangeSubscriptionQuery,
  useUpdateDefaultPaymentMethodMutation
} from 'services/myAccount/myAccount';

import { selectUser } from 'store';

import PaymentMethods from 'features/PaymentMethods/PaymentMethods';
import EncryptedBadge from 'shared/EncryptedBadge';
import Loader from 'shared/Loader';
import { notifySuccess } from 'shared/Toast/Toast';
import AppointmentCard from 'widgets/PaymentCheckout/AppointmentCard';
import UpdatePlanCard from 'widgets/UpdatePlanCard';

import { useAppSelector } from 'hooks';
import { PlanCodes } from 'utils/enums';
import { getPaymentNumbers, getPricePoint, handleRequestCatch } from 'utils/helpers';

import { PaymentMethod } from 'models/payments.types';

import { PaymentCheckoutProps } from './paymentCheckout.types';

const PaymentCheckout: React.FC<PaymentCheckoutProps> = ({
  onProceed,
  selectedPlan,
  existingPlan,
  category,
  time,
  couponCode,
  providerName,
  loading,
  freeAppointmentInfo,
  selectedPricePoint,
  newPlanSubtitle,
  isUpdatingRecommendation
}) => {
  const { activePricePoint } = useAppSelector(selectUser);
  const [enableToChange, toggleEnableToChange] = useToggle(
    existingPlan?.planCode !== PlanCodes.WeightManagementMembership ||
      selectedPlan.planCode === PlanCodes.WeightManagementMembership
  );
  const [updateDefaultPaymentMethod, { isLoading: isUpdatingDefaultPaymentMethod }] =
    useUpdateDefaultPaymentMethodMutation();

  const [checkFreeAppointments, { data: ins, isLoading: isGettingPaymentData }] =
    useCheckFreeAppointmentsMutation();
  const insuranceCoverageData = ins?.data?.insuranceEligibility;
  const isAccepted =
    insuranceCoverageData?.status &&
    ['ACTIVE', 'ACTIVE_AT_RISK'].includes(insuranceCoverageData?.status);
  const isDeclined =
    insuranceCoverageData?.status &&
    ['INACTIVE', 'UNKNOWN'].includes(insuranceCoverageData?.status);

  const isPercentageDiscount =
    typeof insuranceCoverageData?.coinsurance === 'number' &&
    insuranceCoverageData?.coinsurance > 0;

  const fixedFinalPrice = insuranceCoverageData?.finalCost
    ? `${(insuranceCoverageData.finalCost / 100).toFixed((insuranceCoverageData.finalCost / 100) % 1 !== 0 ? 2 : 0)}`
    : '';

  const { data: balance } = useGetCreditBalanceQuery();
  const { data, isFetching, isLoading, refetch } = useGetCreditCardInformationQuery({
    isInsuranceSubscription: !!isAccepted
  });
  const isChangingPlan =
    selectedPlan.planCode !== existingPlan.planCode ||
    (selectedPricePoint && selectedPricePoint.planPricePointId !== activePricePoint);

  const [
    getPreviewChangeSubscription,
    { data: previewChangeSubscription, isLoading: previewChangeSubscriptionLoading }
  ] = useLazyPreviewChangeSubscriptionQuery();
  const creditBalance = balance?.data.creditBalance;
  const discountAmount = previewChangeSubscription?.data?.newDiscount?.discountAmount;

  const existingPricePoint = getPricePoint(existingPlan.pricePoints, undefined, activePricePoint);

  const plansWithPaymentPerAppointment = [
    PlanCodes.InitialAppointment,
    PlanCodes.FlexCare,
    PlanCodes.LifeMDMembership,
    PlanCodes.TotalCareMembership,
    PlanCodes.UrgentCare
  ];
  const finalCost = isAccepted
    ? fixedFinalPrice
    : !isChangingPlan
      ? existingPricePoint?.subsequentAppointmentCost
      : plansWithPaymentPerAppointment.includes(selectedPlan.planCode as PlanCodes)
        ? selectedPricePoint?.subsequentAppointmentCost
        : selectedPricePoint?.totalCost;
  const { finalPrice } = getPaymentNumbers(finalCost, discountAmount, creditBalance);

  const submitBtnTitle = isChangingPlan
    ? 'Confirm plan change'
    : `Confirm $${freeAppointmentInfo?.isFree ? '0' : finalPrice} payment`;

  const isDisabled =
    isUpdatingDefaultPaymentMethod ||
    loading ||
    isFetching ||
    !enableToChange ||
    previewChangeSubscriptionLoading ||
    isGettingPaymentData;

  const handleFavoritePaymentMethod = async (id: string, cb?: () => void) => {
    if (!id) return;

    try {
      const response = await updateDefaultPaymentMethod({
        id,
        isInsuranceSubscription: !!isAccepted
      }).unwrap();
      await refetch().unwrap();
      notifySuccess(response.message ?? 'Updated default payment method');
      cb?.();
    } catch (e) {
      handleRequestCatch(e as MessageEvent);
    }
  };
  const isFinalPriceMoreThanZero = parseInt(finalPrice) >= 0;

  const getPaymentMethods = (): PaymentMethod[] => {
    return (
      data?.data?.map((paymentMethod) => ({
        ...paymentMethod,
        type: isAccepted ? 'appointments' : 'membership'
      })) ?? []
    );
  };

  useEffect(() => {
    !!time && checkFreeAppointments({ appointmentStartTime: time }).unwrap();
    if (!selectedPricePoint || !isChangingPlan) return;

    const options = {
      planId: selectedPlan._id,
      planPricePointId: selectedPricePoint.planPricePointId,
      ...(couponCode && { couponCode })
    };

    getPreviewChangeSubscription(options);
  }, [selectedPricePoint]);

  return (
    <>
      <Loader isVisible={isLoading || previewChangeSubscriptionLoading || isGettingPaymentData} />
      <div className="mx-auto flex size-full max-w-[960px] flex-col md:rounded-xl md:bg-white">
        {!!insuranceCoverageData && isDeclined ? (
          <span className="block h-full place-content-center text-center text-mXl font-semibold">
            There is some problems with your insurance,
            <br />
            please reach out to our support team
          </span>
        ) : (
          <div className="mx-auto flex w-full max-w-[500px] flex-col gap-8 md:pt-8">
            <h1 className="large-title hidden md:block" data-testid="heading">
              {isFinalPriceMoreThanZero
                ? 'Confirm and Pay'
                : isChangingPlan
                  ? 'Confirm change'
                  : 'Confirm'}
            </h1>
            {!!isAccepted && (
              <div className="mb-6 flex justify-between gap-2 rounded-2xl border border-gray-200 bg-gray-100 p-4 md:mb-8">
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-bold">Your insurance has been accepted</p>
                  <div className="flex gap-1">
                    <Common.Icon name="check-shield" />
                    <div>
                      <p className="text-sm">
                        Your total {isPercentageDiscount ? 'coinsurance' : 'copay'} is
                        {isPercentageDiscount
                          ? ` ${insuranceCoverageData.coinsurance}% `
                          : ` $${fixedFinalPrice} `}
                        for this telehealth appointment.
                      </p>
                      <p className="text-sm text-gray">
                        {isPercentageDiscount ? 'Coinsurance' : 'Copay'} does not include cost of
                        medication.
                      </p>
                    </div>
                  </div>
                </div>
                <Common.Icon className="size-[30px] text-green" name="check-circle" />
              </div>
            )}
            {isChangingPlan || isUpdatingRecommendation ? (
              <>
                {previewChangeSubscription?.data && selectedPricePoint ? (
                  <UpdatePlanCard
                    creditBalance={creditBalance}
                    oldPlan={
                      existingPricePoint
                        ? {
                            planName: existingPlan?.planName || '',
                            pricePoint: existingPricePoint
                          }
                        : undefined
                    }
                    plan={{
                      planName: newPlanSubtitle || selectedPlan.planName,
                      pricePoint: selectedPricePoint
                    }}
                    previewChangeSubscription={previewChangeSubscription.data}
                  />
                ) : null}
              </>
            ) : !!existingPricePoint ? (
              <AppointmentCard
                category={category}
                copayInfo={
                  !!insuranceCoverageData
                    ? {
                        copayAmount: fixedFinalPrice,
                        isPercentageDiscount: isPercentageDiscount
                      }
                    : undefined
                }
                creditBalance={creditBalance}
                discount={discountAmount}
                freeAppointmentInfo={freeAppointmentInfo}
                pricePoint={existingPricePoint}
                providerName={providerName}
                time={time}
              />
            ) : (
              <span>Error, please reach out to our support team</span>
            )}
            {(previewChangeSubscription?.data.migrationHasPayment || isFinalPriceMoreThanZero) && (
              <div className="flex flex-col gap-4">
                <h2 className="text-xl font-bold text-primary-700">Payment method</h2>
                {!isLoading && (
                  <PaymentMethods
                    isInsuranceUser={!!isAccepted}
                    isLoading={isFetching}
                    paymentMethods={getPaymentMethods()}
                    onAddMethod={refetch}
                    onUpdateFavoritePaymentMethod={handleFavoritePaymentMethod}
                  />
                )}
              </div>
            )}
            {existingPlan?.planCode === PlanCodes.WeightManagementMembership &&
              selectedPlan.planCode !== PlanCodes.WeightManagementMembership && (
                <Common.Checkbox checked={enableToChange} onChange={() => toggleEnableToChange()}>
                  I understand that I will lose access to my Weight Management program.
                </Common.Checkbox>
              )}
            <div className="mt-auto flex flex-1 flex-col items-center gap-4">
              <Common.Button
                color="blue"
                dataTestId="confirm_btn"
                disabled={isDisabled}
                isLoading={loading}
                fullWidthOnMobile
                onClick={() => onProceed()}
              >
                {submitBtnTitle}
              </Common.Button>
              <EncryptedBadge />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentCheckout;
