import { Common } from '@thecvlb/design-system';

import { BaseModalProps } from 'modals/modal.types';

import useWidth from 'hooks/useWidth';

const LearnMoreAboutBMI: React.FC<BaseModalProps> = ({ isOpen, onClose }) => {
  const { isMobile } = useWidth();
  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={isMobile} size="lg">
      <div className="flex flex-col gap-4">
        <h3 className="text-xl font-bold text-gray-700 md:bg-gray-50 md:p-8">What is BMI?</h3>
        <p className="md:px-8">
          BMI, or Body Mass Index, is a measure used to estimate a person’s body fat levels based on
          their weight and height. It’s calculated by dividing a person’s weight in kilograms by the
          square of their height in meters. The resulting number is used to classify individuals
          into categories:
        </p>
        <div className="flex flex-col gap-1 rounded-lg bg-secondary-100 p-4 md:mx-8">
          <div className="flex items-center text-mSm md:text-base">
            <p className="basis-1/2 font-bold md:text-base md:font-semibold">Underweight:</p>
            <p className="basis-1/2 max-md:font-semibold">BMI less than 18.5</p>
          </div>
          <div className="flex items-center text-mSm md:text-sm">
            <p className="basis-1/2 font-bold md:text-base md:font-semibold">Normal weight:</p>
            <p className="basis-1/2 max-md:font-semibold">BMI 18.5 to 24.9</p>
          </div>
          <div className="flex items-center text-mSm md:text-sm">
            <p className="basis-1/2 font-bold md:text-base md:font-semibold">Overweight:</p>
            <p className="basis-1/2 max-md:font-semibold">BMI 25 to 29.9</p>
          </div>
          <div className="flex items-center text-mSm md:text-sm">
            <p className="basis-1/2 font-bold md:text-base md:font-semibold">Obesity:</p>
            <p className="basis-1/2 max-md:font-semibold">BMI 30 or higher</p>
          </div>
        </div>
        <p className="md:px-8 md:pb-8">
          It’s important to note that while BMI can be a useful tool for identifying potential
          weight issues in large populations, it has its limitations. For example, it doesn’t
          differentiate between fat and muscle, which tends to be heavier.This means that a very
          muscular person might have a high BMI, even though they’re not overweight or obese.
          Furthermore, it does not take into account factors like age, sex, bone structure, and fat
          distribution, all of which can influence health.In order to qualify for treatment with
          GLP-1 medications, patients must have a BMI of 30 or above, which is classified as
          obesity. It may also be considered for those with a BMI of 27 or above if they have at
          least one weight-related health condition, such as type 2 diabetes, high blood pressure,
          or high cholesterol. Patients who have a BMI between 27-29.9 will be required to complete
          labs before their appointment.
        </p>
        <Common.Button
          className="my-2 md:hidden"
          color="white-alt"
          fullWidthOnMobile
          onClick={onClose}
        >
          Close
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default LearnMoreAboutBMI;
