import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { useCheckFreeAppointmentsMutation } from 'services/appointments/appointments';

import { selectLookup, selectUser } from 'store';

import Loader from 'shared/Loader';
import { notifyError } from 'shared/Toast/Toast';
import PlanCard from 'widgets/createAppointment/Membership/PlanCard';

import { useAppSelector } from 'hooks';
import { DateFormat, PlanCodes } from 'utils/enums';
import { convertNumToWord } from 'utils/helpers';

import { MembershipProps } from './membership.types';

const Membership: React.FC<MembershipProps> = ({
  dataTestId,
  onSelect,
  selectedPlanId,
  loading,
  startTime,
  onClickBack,
  onClickContinue
}) => {
  const [checkFreeAppointments, { data, isLoading }] = useCheckFreeAppointmentsMutation();
  const { activePlanId, activePlanCode, activePricePoint } = useAppSelector(selectUser);
  const { membershipPlans } = useAppSelector(selectLookup);
  const [selectedPlan, setSelectedPlan] = useState('');

  const freeAppointmentInfo = data?.data;

  const findPlan = (planCode: PlanCodes) =>
    membershipPlans.find((plan) => plan.planCode === planCode);

  const CURRENT_PLAN = findPlan(activePlanCode as PlanCodes);
  const CURRENT_PP = CURRENT_PLAN?.pricePoints.find(
    (pp) => pp.planPricePointId === activePricePoint
  );
  const UNLIMITED_PLAN = findPlan(PlanCodes.UnlimitedMembership);
  const UNLIMITED_PP = UNLIMITED_PLAN?.pricePoints.find((pp) => pp.isDefault);

  const handleClick = (type: 'one-time' | 'unlimited') => {
    if (!CURRENT_PP || !CURRENT_PLAN || !UNLIMITED_PLAN || !UNLIMITED_PP) {
      return notifyError('Please try again later');
    }

    const planID = type === 'one-time' ? CURRENT_PLAN._id : UNLIMITED_PLAN._id;
    const pp = type === 'one-time' ? CURRENT_PP : UNLIMITED_PP;
    const freeAppointmentInfoData =
      type === 'one-time' && freeAppointmentInfo
        ? {
            bookedFreeAppointmentDate:
              freeAppointmentInfo.freePerMonthAlreadyBookedAppointmentStartTime || '',
            freeAppointmentsAmount: freeAppointmentInfo.freePerMonthAppointmentsLeft,
            isFree: freeAppointmentInfo.isFree,
            periodEnd: freeAppointmentInfo.subscriptionMonthEnd,
            periodStart: freeAppointmentInfo.subscriptionMonthStart
          }
        : undefined;

    onSelect({
      newPlanID: planID,
      newPP: pp,
      freeAppointmentInfo: freeAppointmentInfoData
    });
    setSelectedPlan(planID);
  };

  const handleUpdatedLoading = () => {
    if (!selectedPlanId) {
      handleClick('one-time');
    } else {
      setSelectedPlan(selectedPlanId);
    }
  };

  useEffect(handleUpdatedLoading, [loading]);

  const onInit = async () => {
    if (!startTime || !CURRENT_PLAN) return;

    const { data: res } = await checkFreeAppointments({ appointmentStartTime: startTime }).unwrap();

    const freeAppointmentInfo = {
      bookedFreeAppointmentDate: res.freePerMonthAlreadyBookedAppointmentStartTime || '',
      freeAppointmentsAmount: res.freePerMonthAppointmentsLeft,
      isFree: res.isFree,
      periodEnd: res.subscriptionMonthEnd || '',
      periodStart: res.subscriptionMonthStart || ''
    };

    onSelect({
      freeAppointmentInfo,
      newPlanID: CURRENT_PLAN._id,
      newPP: CURRENT_PP
    });
  };

  useEffect(() => {
    onInit();
  }, []);

  const planContentClassName = 'my-8 flex w-full flex-col gap-4 md:flex-row md:gap-8';
  const planClassName = (planID = '') =>
    classNames(
      'cursor-pointer flex flex-col justify-start text-left w-full md:w-1/2 rounded-xl p-6',
      {
        'border border-gray-200': !loading && !isLoading,
        'bg-blue-50 border-blue': selectedPlan && selectedPlan === planID
      }
    );

  const titleText =
    CURRENT_PLAN?.planCode === PlanCodes.TotalCareMembership
      ? 'You’ve already used your appointment for the month.'
      : 'Would you like to upgrade to unlimited appointments?';

  return (
    <>
      {loading || isLoading ? (
        <div className="md:min-h-[360px]">
          <Loader isVisible />
          <div className={planContentClassName}>
            <div className={classNames(planClassName(''), 'block h-[230px] w-full justify-end')} />
            <div className={classNames(planClassName(''), 'block h-[230px] w-full justify-end')} />
          </div>
        </div>
      ) : freeAppointmentInfo && freeAppointmentInfo.isFree ? (
        <div className="flex flex-col items-center gap-4 text-primary-700 md:gap-8">
          <Common.Illustration name="calendar" />
          <h2 className="large-title text-center">You have a free appointment available!</h2>
          <p className="text-center">
            Thanks for being a {CURRENT_PLAN?.planName || ''} member! You have{' '}
            {convertNumToWord(freeAppointmentInfo.freePerMonthAppointmentsLeft)} free appointment
            available to be scheduled between{' '}
            <span className="font-bold">
              {dayjs(freeAppointmentInfo.subscriptionMonthStart).format(DateFormat.MMM_DD)}
            </span>{' '}
            and{' '}
            <span className="font-bold">
              {dayjs(freeAppointmentInfo.subscriptionMonthEnd).format(DateFormat.MMM_DD)}
            </span>
            .
          </p>
        </div>
      ) : (
        <>
          <h1 className="large-title my-4 md:mb-8 md:mt-0" data-testid="header">
            {titleText}
          </h1>
          {!!freeAppointmentInfo?.freePerMonthAlreadyBookedAppointmentStartTime && (
            <Common.Alert type="info">
              <span className="text-primary-700">
                Your free appointment for the period from{' '}
                <span className="font-bold">
                  {dayjs(freeAppointmentInfo.subscriptionMonthStart).format(DateFormat.MMM_DD)}
                </span>{' '}
                to{' '}
                <span className="font-bold">
                  {dayjs(freeAppointmentInfo.subscriptionMonthEnd).format(DateFormat.MMM_DD)}
                </span>{' '}
                {dayjs(freeAppointmentInfo.freePerMonthAlreadyBookedAppointmentStartTime).isBefore(
                  dayjs()
                )
                  ? 'took place on'
                  : 'is scheduled for'}{' '}
                <span className="font-bold">
                  {dayjs(
                    freeAppointmentInfo.freePerMonthAlreadyBookedAppointmentStartTime || ''
                  ).format(DateFormat.MMM_DD)}
                </span>
                .
              </span>
            </Common.Alert>
          )}
          <div className={planContentClassName}>
            <button
              className={planClassName(activePlanId)}
              data-testid="one-time-button"
              onClick={() => handleClick('one-time')}
            >
              {CURRENT_PLAN && CURRENT_PP && (
                <PlanCard
                  activePlanId={CURRENT_PLAN._id}
                  dataTestId={dataTestId}
                  freeAppointmentStatus={
                    freeAppointmentInfo?.isFree ||
                    CURRENT_PLAN.planCode !== PlanCodes.TotalCareMembership
                      ? undefined
                      : dayjs(
                            freeAppointmentInfo?.freePerMonthAlreadyBookedAppointmentStartTime
                          ).isBefore(dayjs())
                        ? 'used'
                        : 'booked'
                  }
                  plan={CURRENT_PLAN}
                  planName="Pay for this appointment"
                  price={CURRENT_PP.subsequentAppointmentCost}
                  selectedPlan={selectedPlan}
                />
              )}
            </button>
            <button
              className={planClassName(UNLIMITED_PLAN?._id)}
              data-testid="unlimited-button"
              onClick={() => handleClick('unlimited')}
            >
              {UNLIMITED_PLAN && (
                <PlanCard
                  activePlanId={activePlanId}
                  dataTestId={dataTestId}
                  plan={UNLIMITED_PLAN}
                  planName={`Update to ${UNLIMITED_PLAN.planName}`}
                  price={UNLIMITED_PP?.totalCost || '99'}
                  selectedPlan={selectedPlan}
                />
              )}
            </button>
          </div>
        </>
      )}
      {!loading && !isLoading && (
        <div className="mt-auto flex justify-center gap-4 max-md:flex-col md:mt-8">
          {!!onClickBack && (
            <Common.Button
              className="mt-auto"
              color="white-alt"
              fullWidthOnMobile
              onClick={onClickBack}
            >
              Back
            </Common.Button>
          )}
          <Common.Button
            className="mt-auto"
            color="blue"
            dataTestId="continue_btn"
            postIcon="arrow-right"
            fullWidthOnMobile
            onClick={onClickContinue}
          >
            Next
          </Common.Button>
        </div>
      )}
    </>
  );
};

export default Membership;
