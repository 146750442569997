import { JSX } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { PricePoint } from 'models/plans.types';

interface PlanRadioButtonProps {
  content: {
    advantages: JSX.Element;
    newPrice: string;
    oldPrice?: string;
    subtitle?: string;
    title: string;
  };

  onSelect: (v: PricePoint) => void;
  pricePoint: PricePoint;
  selectedValue?: string;
}

const PlanRadioButton: React.FC<PlanRadioButtonProps> = ({
  selectedValue,
  onSelect,
  pricePoint,
  content: { newPrice, oldPrice, title, subtitle, advantages }
}) => {
  const isSelected = selectedValue === pricePoint.planPricePointId;
  const wrapperClassName = classNames(
    'flex flex-col gap-2.5 rounded-2xl p-4 border transition-colors',
    isSelected ? 'bg-secondary-50 border-secondary border-2' : 'border-gray-200 m-px'
  );

  return (
    <>
      <button className={wrapperClassName} onClick={() => onSelect(pricePoint)}>
        <Common.RadioButton
          checked={isSelected}
          className="w-full !items-start text-primary-700"
          color="blue"
          onChange={() => {}}
        >
          <div className="ml-1 flex flex-col text-left">
            <span className="text-base font-bold md:text-lg">{title}</span>
            {!!subtitle && <span className="text-mSm text-gray md:text-sm">{subtitle}</span>}
          </div>
          <span className="ml-auto text-base font-bold">
            {oldPrice && (
              <span className="mr-1 font-medium text-gray line-through">${oldPrice}</span>
            )}
            ${newPrice}
          </span>
        </Common.RadioButton>

        {advantages}
      </button>
    </>
  );
};

export default PlanRadioButton;
