import classNames from 'classnames';

import { getOffset } from './rangeSection.settings';
import { RangeScaleItemProps } from './rangeSection.types';

const RangeSection: React.FC<RangeScaleItemProps> = ({
  color,
  end,
  isValueOutOfRange,
  label,
  showRangerMarker,
  start,
  value
}) => {
  const isLow = label === 'Low';
  const isHigh = label === 'High';
  const rangeSectionWidth = end - start;
  const rangeSectionMarkerValue = value - start;
  const rangeSectionMarkerValueInPercentage = showRangerMarker
    ? Math.round((rangeSectionMarkerValue * 100) / rangeSectionWidth)
    : 0;

  const rangeSectionClasses = classNames(
    'relative w-full h-2',
    { 'rounded-l-[32px]': isLow },
    { 'rounded-r-[32px]': isHigh },
    { 'bg-yellow': color === 'yellow' },
    { 'bg-primary-300': color === 'blue' },
    { 'bg-gray opacity-30': color === 'gray' }
  );
  const rangeMarkerClasses = showRangerMarker
    ? classNames(
        '-mx-1.5 absolute -top-[9px] w-0 h-0 rounded-b-xl rounded-t-lg border-l-[8px] border-l-transparent border-t-[8px] border-t-gray-700 border-r-[8px] border-r-transparent',
        isValueOutOfRange
          ? {
              'left-0': isLow,
              'right-0': isHigh
            }
          : getOffset(rangeSectionMarkerValueInPercentage)
      )
    : '';

  return (
    <div className={rangeSectionClasses}>
      {showRangerMarker && (
        <div className={rangeMarkerClasses} data-testid="lab_result_metric_range_section" />
      )}
    </div>
  );
};

export default RangeSection;
