import { Common } from '@thecvlb/design-system/lib/src';

import { MifStopperProps } from './mifStopper.types';

const MifStopper: React.FC<MifStopperProps> = ({ onContactSupport, onLeave }) => {
  return (
    <div className="flex flex-col gap-2 text-primary-700 max-md:h-full">
      <h3 className="wm-signup-title">Your health comes first</h3>
      <span className="mb-4">
        It looks like some of the conditions you selected may prevent you from continuing with this
        appointment. For your health and safety, we recommend discussing alternative care options
        with a healthcare professional who can best meet your needs.
      </span>
      <span>If you have questions or need guidance, please reach out to us for support.</span>
      <div className="mt-auto flex flex-col gap-4 md:mt-8 md:items-center">
        <Common.Button
          color="blue"
          preIcon="phone-filled"
          fullWidthOnMobile
          onClick={onContactSupport}
        >
          Contact support
        </Common.Button>
        <Common.Button fullWidthOnMobile onClick={onLeave}>
          Leave
        </Common.Button>
      </div>
    </div>
  );
};

export default MifStopper;
