import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLazyGetOrchestrateSessionInfoQuery } from 'services/auth/auth';
import { useAddInsuranceMutation, useLazyGetInsurancesQuery } from 'services/insurance/insurance';
import { GetInsurancesResProps } from 'services/insurance/insurance.types';
import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';
import { GetMyAccountResProps } from 'services/myAccount/myAccount.types';

import { selectUser } from 'store';
import { setWholeState } from 'store/orchestrate-signup/orchestrateSlice';

import { defineFlowSteps } from 'containers/MigrateToWM/migrateToWM.settings';
import {
  ContentProps,
  MIGRATE_TO_WM_FLOW_STEPS,
  StepName
} from 'containers/MigrateToWM/migrateToWM.types';
import { notifyError } from 'shared/Toast/Toast';

import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { PathName } from 'utils/enums';

import useWeightManagement from './useWeightManagement';

export const useMigrateToWM = () => {
  const dispatch = useAppDispatch();
  const [getMyAccount] = useLazyGetMyAccountQuery();
  const [getInsurance] = useLazyGetInsurancesQuery();
  const [getSession, { isLoading }] = useLazyGetOrchestrateSessionInfoQuery();

  const [addInsurance] = useAddInsuranceMutation();
  const { isAsyncPlan } = useWeightManagement();

  const { identityVerified: isVerified, sexAtBirth, accessToken } = useAppSelector(selectUser);

  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [steps, setSteps] = useState([...MIGRATE_TO_WM_FLOW_STEPS]);
  const query = useQuery();
  const navigate = useNavigate();
  const currentStep = (query.get('s') ?? '') as StepName;

  const fetchData = async (): Promise<
    | {
        error: string;
        insuranceData: null;
        myAccountData: null;
      }
    | {
        error: null;
        insuranceData: GetInsurancesResProps;
        myAccountData: GetMyAccountResProps;
      }
  > => {
    let myAccountData = null;
    let insuranceData = null;
    let error = null;

    try {
      setIsFetching(true);
      myAccountData = await getMyAccount().unwrap();
      insuranceData = await getInsurance().unwrap();
      return { error: null, insuranceData, myAccountData };
    } catch (e) {
      error = (e as Error).message ?? 'Error fetching data';
      return { error, insuranceData: null, myAccountData: null };
    } finally {
      setIsFetching(false);
    }
  };

  const exitFlow = async () => {
    try {
      await fetchData();
    } finally {
      navigate(PathName.Dashboard);
    }
  };

  const moveToStep: ContentProps['moveToStep'] = (type, extraSearch = ''): void | Promise<void> => {
    if (typeof type === 'object') {
      const { answer, step } = type;
      switch (step) {
        case 'wm-insurance':
          const insuranceSteps: StepName[] = ['pre-insurance', 'lower-price', 'insurance'];
          // we'll set true for uninsured or medicare patients
          if (answer === true) {
            const filteredSteps = steps.filter((step) => !insuranceSteps.includes(step));
            setSteps(filteredSteps);
          } else {
            const doesAlreadyHaveInsuranceSteps = insuranceSteps.every((s) => steps.includes(s));
            if (!doesAlreadyHaveInsuranceSteps) {
              const indexOfStepBeforeInsurance = steps.indexOf('wm-medical-conditions');
              const newArr = [...steps];
              newArr.splice(indexOfStepBeforeInsurance + 1, 0, ...insuranceSteps);
              setSteps(newArr);
            }
          }
          return moveToStep('next');
        case 'pre-insurance':
          if (answer) {
            return moveToStep('next');
          } else {
            const nextStepAfterInsurance = steps[steps.indexOf('insurance') + 1];
            return moveToStep(nextStepAfterInsurance);
          }
        case 'verify-identity':
          if (answer) {
            return moveToStep('next');
          } else {
            const nextStepAfterIdentity = steps[steps.indexOf('verify-identity') + 1];
            return moveToStep(nextStepAfterIdentity);
          }
        default:
          break;
      }
    } else {
      const additionalSearch = extraSearch ? '&' + extraSearch : '';
      if (type === 'prev') {
        return navigate(-1);
      } else if (type === 'next') {
        const currentStepIndex = steps.indexOf(currentStep);

        let nextStep = steps[currentStepIndex + 1];
        // here is additional logic to skip verify identity steps if user is already verified by crosscheck
        if (isVerified && steps.includes('verify-identity')) {
          nextStep = steps[steps.indexOf('verify-identity') + 1];
        }
        if (currentStepIndex + 1 >= steps.length || !steps.includes(nextStep)) {
          exitFlow();
          return;
        }
        return navigate({ search: `s=${nextStep}${additionalSearch}` });
      } else {
        if (!steps.includes(type)) {
          notifyError('Something went wrong, please try again');
          return navigate({ search: `s=${steps[0]}` }, { replace: true });
        }
        navigate({ search: `s=${type}${additionalSearch}` });
      }
    }
  };

  const handleSelectInsurance = async (value: boolean) => {
    if (value) {
      moveToStep({ answer: value, step: 'pre-insurance' });
    } else {
      setIsFetching(true);
      await addInsurance({
        hasInsurance: value
      })
        .unwrap()
        .finally(() => {
          setIsFetching(false);
          moveToStep({ answer: value, step: 'pre-insurance' });
        });
    }
  };

  const onInit = () => {
    const hasUncompletedSession = sessionStorage.getItem('suggest-wm-after-login') === 'true';

    hasUncompletedSession &&
      getSession(accessToken)
        .unwrap()
        .then(({ data }) => {
          if ('mif_qa' in data) {
            dispatch(setWholeState(data));
          }
        });
    const getData = async () => {
      try {
        setLoading(true);
        const { myAccountData, insuranceData, error } = await fetchData();
        if (error !== null) {
          return notifyError(error);
        }
        const {
          data: { activePlanCode, identityVerified, isFirstAppointmentCompleted }
        } = myAccountData;
        const {
          data: { insurances, hasInsurance }
        } = insuranceData;

        const filteredSteps = defineFlowSteps(MIGRATE_TO_WM_FLOW_STEPS, {
          activePlanCode,
          hasInsurance,
          identityVerified,
          insurances,
          isAsyncPlan,
          isFirstAppointmentCompleted,
          sexAtBirth,
          hasUncompletedSession
        });
        setSteps(filteredSteps);
        if (!currentStep || !filteredSteps.includes(currentStep)) {
          navigate({ search: `s=${filteredSteps[0]}` }, { replace: true });
        }
      } catch (e) {
        notifyError((e as Error).message ?? 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };
    getData();
  };

  useEffect(onInit, []);

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, [currentStep]);

  return {
    currentStep,
    handleSelectInsurance,
    isFetching,
    loading: loading || isLoading,
    moveToStep,
    steps
  };
};
