import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MembershipPlan } from 'models/plans.types';

import { ProviderStateProps, SignUpProps } from './signup.types';

export const initialState: SignUpProps = {
  doctor: {
    bookedSlotId: '',
    displayName: '',
    email: '',
    id: '',
    profileImage: '',
    rating: 0
  },
  dynamicLink: '',
  expiredToken: '',
  firstAppointment: {
    _id: '',
    appointmentDescription: '',
    appointmentMethod: 'video',
    appointmentTime: {
      endTime: '',
      startTime: ''
    },
    appointmentTypeId: '',
    bookedSlotId: '',
    categoryDisplayName: '',
    doctorId: ''
  },
  membershipPlans: [],
  promoVisited: '',
  selectedPricePoint: undefined,
  signupOptions: {
    approxQueueTime: '',
    campaignId: '',
    flow: '',
    flowVersion: '',
    mockJoinedPatients: 0,
    payWithInsurance: null,
    plan: '',
    predefinedCategory: ''
  },
  statesWhereAsapOptionAvailable: [],
  user: {
    _id: '',
    accessToken: '',
    dob: '',
    doctorId: '',
    doctorLicenseState: '',
    email: '',
    firstName: '',
    gender: '',
    hasSubscription: false,
    initialLoginVideo: '',
    isAsapOptionAvailable: false,
    isInitialLogin: true,
    isInstantAppointment: false,
    isUnlimitedPlan: false,
    lastName: '',
    mailingAddress: {
      address: '',
      city: '',
      state: '',
      zipCode: ''
    },
    nickName: '',
    password: '',
    phoneNumber: '',
    planCode: '',
    planId: '',
    preferredPharmacyID: 0,
    pricePointId: '',
    refreshToken: '',
    sexAtBirth: '',
    status: '',
    timezone: ''
  }
};

const singUpSlice = createSlice({
  initialState,
  name: 'signUp',
  reducers: {
    clearFirstAppointment: (state) => {
      state.firstAppointment = initialState.firstAppointment;
    },
    clearProviderState: (state) => {
      state.doctor = initialState.doctor;
    },
    clearUserState: (state) => {
      state.user = initialState.user;
      state.signupOptions = initialState.signupOptions;
    },
    setDynamicLink: (state, action: PayloadAction<SignUpProps['dynamicLink']>) => {
      state.dynamicLink = action.payload;
    },
    setExpiredToken: (state, action: PayloadAction<SignUpProps['expiredToken']>) => {
      state.expiredToken = action.payload;
    },
    setFirstAppointmentState: (
      state,
      action: PayloadAction<Partial<SignUpProps['firstAppointment']>>
    ) => {
      state.firstAppointment = { ...state.firstAppointment, ...action.payload };
    },
    setMembershipPlans: (state, action: PayloadAction<MembershipPlan[]>) => {
      state.membershipPlans = action.payload;
    },
    setPromoVisited: (state, action: PayloadAction<SignUpProps['promoVisited']>) => {
      state.promoVisited = action.payload;
    },
    setProviderState: (state, action: PayloadAction<Partial<ProviderStateProps>>) => {
      state.doctor = { ...state.doctor, ...action.payload };
    },
    setSelectedPricePoint: (state, action: PayloadAction<SignUpProps['selectedPricePoint']>) => {
      state.selectedPricePoint = action.payload;
    },
    setSignupOptions: (state, action: PayloadAction<Partial<SignUpProps['signupOptions']>>) => {
      state.signupOptions = { ...state.signupOptions, ...action.payload };
    },
    setUserState: (state, action: PayloadAction<Partial<SignUpProps['user']>>) => {
      state.user = { ...state.user, ...action.payload };
    },
    setStatesWhereAshIsAvailable: (state, action: PayloadAction<string[]>) => {
      state.statesWhereAsapOptionAvailable = action.payload;
    }
  }
});

export const {
  setUserState,
  setProviderState,
  setFirstAppointmentState,
  clearUserState,
  setMembershipPlans,
  clearProviderState,
  clearFirstAppointment,
  setDynamicLink,
  setExpiredToken,
  setPromoVisited,
  setSignupOptions,
  setSelectedPricePoint,
  setStatesWhereAshIsAvailable
} = singUpSlice.actions;

export default singUpSlice.reducer;
