import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import {
  useAddAppointmentMutation,
  useCancelAppointmentMutation,
  useCheckPaymentInfoQuery,
  useRescheduleAppointmentMutation
} from 'services/appointments/appointments';

import { selectAppointments, selectUser } from 'store';

import { buildBodyForApptSchedule } from 'containers/CreateAppointment/createAppointment.settings';
import CancellationReason from 'modals/CanceletionReason';
import Loader from 'shared/Loader';
import { notifySuccess } from 'shared/Toast/Toast';
import DateAndTime from 'widgets/createAppointment/DateAndTime';

import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { DateFormat, PathName } from 'utils/enums';
import {
  checkCompletedOnboardingAppt,
  checkWmNotCompletedOnboarding,
  handleRequestCatch
} from 'utils/helpers';

import { AppointmentTime } from 'models/appointment.types';

import { RescheduleAppointmentProps } from './rescheduleAppointment.types';

const RescheduleAppointment: React.FC<RescheduleAppointmentProps> = ({ appointment }) => {
  const logEvent = useAnalytics();
  const { activePlanCode, doctorId, accessToken } = useAppSelector(selectUser);
  const { appointmentsList } = useAppSelector(selectAppointments);
  const [reschedule, { isLoading }] = useRescheduleAppointmentMutation();
  const [addAppointment, { isLoading: isLoadingAddAppointment }] = useAddAppointmentMutation();
  const { data, isFetching } = useCheckPaymentInfoQuery(appointment._id);
  const [cancel, { isLoading: isCanceling }] = useCancelAppointmentMutation();
  const [appointmentTime, setAppointmentTime] = useState<AppointmentTime>({
    endTime: '',
    startTime: ''
  });
  const [combinedData, setCombinedData] = useState({ doctorDisplayName: '', doctorId: '' });
  const [showModal, toggleShowModal] = useToggle(false);
  const navigate = useNavigate();

  const navigateToAppointments = () => navigate(PathName.Appointments);

  const handleCancelThen = () => {
    return addAppointment(
      buildBodyForApptSchedule({
        ...appointmentTime,
        ...appointment,
        appointmentTypeId: appointment.appointmentType._id,
        callType: appointment.appointmentMethod,
        displayName: '',
        doctorId: combinedData?.doctorId,
        isWeightManagementAppointment: false,
        uploadRequired: false
      })
    );
  };

  const handleRescheduleAppointmentCatch = (e: MessageEvent) => {
    handleRequestCatch(e, 'Cannot reschedule an appointment, please try again later');
  };

  const handleRescheduleAppointmentThen = () => {
    notifySuccess('Appointment is rescheduled');
    navigateToAppointments();
  };

  const handleRescheduleQueueAppointment = () => {
    cancel({ appointmentId: appointment._id })
      .unwrap()
      .then(handleCancelThen)
      .catch(handleRescheduleAppointmentCatch)
      .finally(handleRescheduleAppointmentThen);
  };

  const rescheduleAppointment = () => {
    if (appointment) {
      if (appointment.category === 'queue') {
        handleRescheduleQueueAppointment();
        return;
      }
      reschedule({
        appointmentId: appointment._id,
        appointmentTime,
        doctorId: combinedData?.doctorId
      })
        .unwrap()
        .then(handleRescheduleAppointmentThen)
        .catch(handleRescheduleAppointmentCatch);
    }
  };

  const updateDate = (
    type: 'date' | 'time',
    startTime: string,
    endTime = '',
    combinedDoctorId?: string,
    doctorDisplayName = ''
  ) => {
    setCombinedData({
      doctorDisplayName,
      doctorId: combinedDoctorId ? combinedDoctorId : doctorId
    });
    if (type === 'time') {
      setAppointmentTime({ endTime, startTime });
    }
  };

  const handleCancelAppointmentThen = () => {
    notifySuccess('Appointment is canceled');
    navigateToAppointments();
  };

  const cancelAppointment = (cancelReason: string, cancelReasonMessage?: string) => {
    cancel({ appointmentId: appointment._id, cancelReason, cancelReasonMessage })
      .unwrap()
      .then(handleCancelAppointmentThen)
      .catch(handleRequestCatch);
  };

  const completedOnboarding = checkCompletedOnboardingAppt(appointmentsList);
  const wmNotCompletedOnboarding = checkWmNotCompletedOnboarding(
    activePlanCode,
    completedOnboarding
  );

  const calculateBufferPeriod = () => {
    if (!wmNotCompletedOnboarding) {
      return 0;
    } else {
      return dayjs(appointment.appointmentTime?.startTime).diff(dayjs(), 'days');
    }
  };

  const handleClickCancelAppointment = () => {
    logEvent('reschedule_appt_cancel_appt_btn_click');
    toggleShowModal();
  };

  return (
    <>
      <Loader isVisible={isLoading || isLoadingAddAppointment || isFetching} />
      {data?.data.isFree && data.data.subscriptionHasFreePerMonthFeature && (
        <div className="mx-auto mb-3 w-full max-w-screen-sm md:mb-8">
          <Common.Alert type="info">
            <span className="text-primary-700">
              Your appointment is currently scheduled for{' '}
              <span className="font-bold">
                {appointment.appointmentTime
                  ? dayjs(appointment.appointmentTime?.startTime || '').format(DateFormat.MMM_DD)
                  : 'today'}
              </span>
              , and will use an appointment credit for the period from{' '}
              <span className="font-bold">
                {dayjs(data.data.subscriptionMonthStart).format(DateFormat.MMM_DD)}
              </span>{' '}
              to{' '}
              <span className="font-bold">
                {dayjs(data.data.subscriptionMonthEnd).format(DateFormat.MMM_DD)}
              </span>
              . If you choose a date outside this range, you may be charged.
            </span>
          </Common.Alert>
        </div>
      )}
      <DateAndTime
        accessToken={accessToken}
        appointmentId={appointment._id}
        appointmentTypeId={appointment.appointmentType._id}
        bufferPeriodDays={calculateBufferPeriod()}
        doctorId={appointment.doctorId}
        initialDate={dayjs(appointment.appointmentTime?.startTime).format(DateFormat.YYYY_MM_DD)}
        updateStartDate={updateDate}
        isReschedule
        onSelect={rescheduleAppointment}
      />
      {!wmNotCompletedOnboarding && (
        <Common.Button
          className="mx-auto mt-10"
          color="red-alt"
          dataTestId="cancel_appointment"
          disabled={isCanceling}
          onClick={handleClickCancelAppointment}
        >
          Cancel appointment
        </Common.Button>
      )}
      <CancellationReason
        close={toggleShowModal}
        isOpen={showModal}
        onConfirm={cancelAppointment}
      />
    </>
  );
};

export default RescheduleAppointment;
