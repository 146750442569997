import { NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { IconProps } from '@thecvlb/design-system/lib/src/common';

import { LINK_TO_ACCESSIBILITY } from 'constants/externalLinks';
import useAnalytics from 'hooks/useAnalytics';
import { PathName } from 'utils/enums';

import packageInfo from '../../../package.json';

const LINKS: {
  icon: IconProps['name'];
  name: string;
  to: string;
}[] = [
  {
    icon: 'lock',
    name: 'Legal',
    to: PathName.Legal
  },
  {
    icon: 'accessibility',
    name: 'Accessibility',
    to: LINK_TO_ACCESSIBILITY
  }
];

const About = () => {
  useTitle('LifeMD - About');

  const logEvent = useAnalytics();

  return (
    <div className="flex flex-col">
      {LINKS.map((el) => (
        <NavLink
          className="flex w-full cursor-pointer items-center gap-2 py-4 text-left text-mBase font-semibold md:font-bold"
          data-acsb-custom-trigger="true"
          target={el.to === LINK_TO_ACCESSIBILITY ? '_blank' : undefined}
          to={el.to}
          onClick={() => logEvent(`my_account_${el.name}_mitem_click`)}
        >
          <Common.Icon className="size-5 text-gray-700" name={el.icon} />
          <span className="flex-1">{el.name}</span>
          <Common.Icon className="size-5 text-gray" name="arrow-right" />
        </NavLink>
      ))}
      <span className="mx-auto mt-8 text-gray">v{packageInfo.version}</span>
    </div>
  );
};
export default About;
