import { JSX } from 'react';

const Heading: React.FC<{
  category: string;
  subtitle?: string | JSX.Element;
  title: string | JSX.Element;
}> = ({ title, subtitle, category }) => {
  return (
    <div className="flex flex-col gap-2 md:gap-1">
      <span className="wm-signup-subtitle block">{category}</span>
      <h2 className="wm-signup-title" data-testid="header">
        {title}
      </h2>
      {!!subtitle && <h3 className="text-primary-700">{subtitle}</h3>}
    </div>
  );
};

export default Heading;
