import { useEffect } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import {
  useGetCreditBalanceQuery,
  useGetCreditCardInformationQuery,
  useLazyPreviewChangeSubscriptionQuery,
  useUpdateDefaultPaymentMethodMutation
} from 'services/myAccount/myAccount';

import { selectUser } from 'store';

import PaymentMethods from 'features/PaymentMethods';
import EncryptedBadge from 'shared/EncryptedBadge';
import Loader from 'shared/Loader';
import { notifySuccess } from 'shared/Toast/Toast';
import UpdatePlanCard from 'widgets/UpdatePlanCard';

import { useAppSelector } from 'hooks';
import { getPaymentNumbers, handleRequestCatch } from 'utils/helpers';

import { PaymentMethod } from 'models/payments.types';

import { CheckoutProps } from './сheckout.types';

const Checkout: React.FC<CheckoutProps> = ({
  currentPP,
  newPricePoint,
  onContinue,
  loading,
  userCurrentPPType
}) => {
  const [
    getPreviewChangeSubscription,
    { data: previewChangeSubscription, isLoading: previewChangeSubscriptionLoading }
  ] = useLazyPreviewChangeSubscriptionQuery();
  const { data: balance } = useGetCreditBalanceQuery();

  const {
    data: paymentMethodsData,
    isFetching,
    isLoading,
    refetch
  } = useGetCreditCardInformationQuery();
  const [updateDefaultPaymentMethod, { isLoading: isUpdatingDefaultPaymentMethod }] =
    useUpdateDefaultPaymentMethodMutation();

  const { activePlanId } = useAppSelector(selectUser);
  const creditBalance = balance?.data.creditBalance;
  const finalCost = newPricePoint.totalCost;
  const { finalPrice } = getPaymentNumbers(finalCost, undefined, creditBalance);

  const isFinalPriceMoreThanZero = parseInt(finalPrice) >= 0;

  const getPaymentMethods = (): PaymentMethod[] => {
    return (
      paymentMethodsData?.data?.map((paymentMethod) => ({
        ...paymentMethod,
        type: 'membership'
      })) ?? []
    );
  };

  const handleFavoritePaymentMethod = async (id: string, cb?: () => void) => {
    if (!id) return;
    try {
      const response = await updateDefaultPaymentMethod({
        id
      }).unwrap();
      await refetch().unwrap();
      notifySuccess(response.message ?? 'Updated default payment method');
      cb?.();
    } catch (e) {
      handleRequestCatch(e as MessageEvent);
    }
  };

  useEffect(() => {
    const options = {
      planId: activePlanId,
      planPricePointId: newPricePoint.planPricePointId
      //   ...(couponCode && { couponCode })
    };

    getPreviewChangeSubscription(options);
  }, [newPricePoint]);

  const isDisabled =
    isUpdatingDefaultPaymentMethod || loading || isFetching || previewChangeSubscriptionLoading;

  return (
    <>
      <Loader isVisible={!!previewChangeSubscriptionLoading || isFetching} />
      {!!previewChangeSubscription?.data && (
        <div className="flex h-full flex-col gap-8">
          <UpdatePlanCard
            creditBalance={creditBalance}
            oldPlan={
              currentPP
                ? {
                    planName: currentPP.planName ?? 'Weight management',
                    pricePoint: currentPP
                  }
                : undefined
            }
            plan={{
              planName:
                userCurrentPPType === 'maintenance'
                  ? 'Care + medication maintenance (Semaglutide included)'
                  : userCurrentPPType === 'bundle'
                    ? 'GLP-1 Weight Management (Semaglutide included)'
                    : 'GLP-1 Weight Management ',

              pricePoint: newPricePoint
            }}
            previewChangeSubscription={previewChangeSubscription.data}
          />
          {(previewChangeSubscription?.data.migrationHasPayment || isFinalPriceMoreThanZero) && (
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-bold text-primary-700">Payment method</h2>
              {!isLoading && (
                <PaymentMethods
                  isLoading={isFetching}
                  paymentMethods={getPaymentMethods() ?? []}
                  onAddMethod={refetch}
                  onUpdateFavoritePaymentMethod={handleFavoritePaymentMethod}
                />
              )}
            </div>
          )}
          <div className="flex flex-col items-center gap-4 max-md:mt-auto md:self-center">
            <Common.Button
              color="blue"
              dataTestId="confirm_btn"
              disabled={isDisabled}
              isLoading={loading}
              fullWidthOnMobile
              onClick={onContinue}
            >
              Confirm Plan change
            </Common.Button>
            <EncryptedBadge />
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
