import { NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { IconProps } from '@thecvlb/design-system/lib/src/common';

import useAnalytics from 'hooks/useAnalytics';
import { ACCOUNT_LEGAL_LINKS } from 'utils/constants';

import packageInfo from '../../../package.json';

const Legal = () => {
  useTitle('LifeMD - Legal');
  const logEvent = useAnalytics();

  const navItemClassName =
    'flex items-center gap-2 py-4 w-full text-left text-mBase cursor-pointer font-semibold md:font-bold';
  const navIconClassName = 'size-5 text-gray';
  const darkGrayNavIconClassName = 'size-5 text-gray-700';

  return (
    <div className="flex flex-col">
      {ACCOUNT_LEGAL_LINKS.map((el) => (
        <a className={navItemClassName} href={el.path} key={el.path}>
          <Common.Icon className={darkGrayNavIconClassName} name={el.icon} />
          <span className="flex-1">{el.name}</span>
          <Common.Icon className={navIconClassName} name="arrow-right" />
        </a>
      ))}
      {[
        // {
        //   name: 'Medicaid Waiver',
        //   path: PathName.MedicaidWaiver,
        //   icon: 'folder'
        // },
      ].map(({ name, path, icon }) => (
        <NavLink
          className={navItemClassName}
          to={path}
          onClick={() => logEvent('my_account_hippa_agreement_item_click')}
        >
          <Common.Icon className={darkGrayNavIconClassName} name={icon as IconProps['name']} />
          <span className="flex-1">{name}</span>
          <Common.Icon className={navIconClassName} name="arrow-right" />
        </NavLink>
      ))}

      <span className="mx-auto mt-8 text-gray">v{packageInfo.version}</span>
    </div>
  );
};
export default Legal;
