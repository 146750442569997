export const DEFAULT_APPOINTMENT_TYPE = 'Health & Wellness';

export const DEFAULT_OPTAVIA_APPOINTMENT_TYPE = 'Optavia - Weight Management';

export const DEFAULT_HRT_APPOINTMENT_TYPE = 'HRT Club Consultation';

export const DEFAULT_WEIGHT_MANAGEMENT_TYPE = 'Weight Management follow-up';

export const DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE = 'Weight Management';

export const DEFAULT_NUTRITION_APPOINTMENT_TYPE = 'Nutrition';

export const DEFAULT_UTI_FLOW_APPOINTMENT_TYPE = 'UTI';

export const PROVIDERS_ITEMS_COUNT = 2;

export const ASAP_OPTION_DATE = 'asap';

export const APPROX_WAIT_TIME = '~30 min wait';

export const DEFAULT_FRONT_DESK_TYPE_FOR_WM_UPGRADE = 'Weight management upgrade';

export const WITHINGS_BODY_PRO_2_SCALE_PRICE = '199';

export const DEFAULT_SCALE_ADDON_CODE = 'withings-body-pro-2-scale';

export const DUMMY_PRICE_OF_AT_HOME_LABS = '58';

export const TRIPLE_THERAPY_PLAN_NAME = 'Triple Therapy';

export const VETERANS_INSURANCE_PROVIDER = 'Veterans Affairs (VA) / TRICARE';
