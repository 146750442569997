import { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { useGetMedicalRecordsQuery } from 'services/patientChart/patientChart';

import { selectPatientChart, selectUser } from 'store';
import { MedicalRecordsProps } from 'store/patientChart/patientChart.types';

import MyChartTitle from 'features/MyChartTitle';
import Table from 'features/Table';
import TimeLine from 'features/TimeLine';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import MedicalRecordsForm from 'widgets/myChart/MedicalRecords/MedicalRecordsForm';

import { useAppSelector } from 'hooks';
import { DateFormat } from 'utils/enums';

const MedicalRecords: React.FC = () => {
  useTitle('LifeMD - Medical records');
  const user = useAppSelector(selectUser);
  const { medicalRecords } = useAppSelector(selectPatientChart);
  const { isLoading } = useGetMedicalRecordsQuery(user.userId);
  const [tableData, setTableData] = useState<{ [key: string]: string }[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [tab, setTab] = useState<keyof MedicalRecordsProps | undefined>();

  const tabs = Object.keys(medicalRecords)
    .map((el) => ({
      label: el.replace(/([a-z])([A-Z])/g, '$1 $2'),
      value: el
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    Object.keys(medicalRecords).length && setTab(tabs[0]?.value as keyof MedicalRecordsProps);
  }, [medicalRecords]);

  useEffect(() => {
    if (tab) setTableData(medicalRecords[tab] || []);
  }, [tab]);

  return (
    <FadeWrapper className="rounded-2xl border-gray-200 md:border md:border-none md:bg-white md:p-8">
      <Loader isVisible={isLoading} />
      <MyChartTitle icon="folder-filled" text="Medical records" />
      {showForm ? (
        <>
          <Common.Alert type="error" colorableBackground>
            Error loading medical history. If you would like to see your past medical records,
            please enter the information below and we will attempt to find your medical records.
          </Common.Alert>
          <MedicalRecordsForm onClickCancel={() => setShowForm(false)} />
        </>
      ) : tab ? (
        <div className="flex flex-col gap-4">
          <Common.Alert type="info" colorableBackground onClose={() => {}}>
            <span className="font-bold md:font-medium">Why am I seeing this?</span> We were able to
            securely pull your medical records.
          </Common.Alert>
          <TimeLine medicalRecords={medicalRecords} />
          <div>
            <h2 className="mt-4 hidden text-lg font-bold text-gray-700 md:block">My records</h2>
            <div className="w-full overflow-auto py-4">
              <Common.Tabs
                data={tabs}
                isWrap={false}
                type="bar"
                onChange={(el) => setTab(el.value as keyof MedicalRecordsProps)}
              />
            </div>
            <Table
              customCol={(el) => {
                if (el.name === 'date') {
                  return <>{dayjs(el.content).format(DateFormat.MMM_D_h_mma)}</>;
                }
              }}
              data={tableData}
              tableClassName="rounded-2xl border md:rounded-none md:border-none"
            />
          </div>
        </div>
      ) : isLoading ? null : (
        <div className="mx-auto mt-4 flex max-w-[500px] flex-col gap-4 rounded-xl border border-gray-200 bg-white px-8 py-12 md:mt-8 md:border-none md:p-0">
          <Common.Logo className="mx-auto size-12 md:size-14" name="cross" />
          <b className="text-gray-700">We weren't able to locate your medical records.</b>
          <p className="text-gray">This may have occurred because:</p>
          <ul className="list-disc pl-6 text-gray">
            <li>Your state may have restrictions on making your medical records visible.</li>
            <li>Your previous provider may not be in our medical records network.</li>
            <li>You lack any recent medical history.</li>
          </ul>
        </div>
      )}
    </FadeWrapper>
  );
};

export default MedicalRecords;
