import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { useGenerateDynamicLinkMutation } from 'services/auth/auth';
import { DynamicLinkActions, GenerateDynamicLinkResProps } from 'services/general/general.types';
import { useGetMembershipPlansQuery } from 'services/lookup/lookup';
import {
  useLazyGetMyAccountQuery,
  useSendRecommendationDecisionMutation,
  useUpdateMyAccountMutation
} from 'services/myAccount/myAccount';
import { GetMyAccountResProps } from 'services/myAccount/myAccount.types';

import { selectLookup, selectUser } from 'store';
import { setUser } from 'store/user/userSlice';

import { MembershipData } from 'containers/CreateAppointment/createAppointment.types';
import { notifyToast } from 'shared/Toast/Toast';
import PaymentCheckout from 'widgets/PaymentCheckout';

import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { PathName, PlanCodes } from 'utils/enums';
import { getPricePoint, handleRequestCatch } from 'utils/helpers';

const Checkout = () => {
  useTitle('Checkout');
  const query = useQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activePlanId, accessToken } = useAppSelector(selectUser);
  const { membershipPlans } = useAppSelector(selectLookup);

  const [submitDecision, { isLoading: isSendingRecommendationAnswer }] =
    useSendRecommendationDecisionMutation();
  const [updateMyAccount, { isLoading: isLoadingUpdateMyAccount }] = useUpdateMyAccountMutation();
  const [getMyAccount, { isFetching }] = useLazyGetMyAccountQuery();
  const [generateDynamicLink, { isLoading: isLoadingGeneratingLink }] =
    useGenerateDynamicLinkMutation();
  const { isLoading } = useGetMembershipPlansQuery({ isComponentPlan: false });

  const newPlanID = query.get('newPlanID') ?? activePlanId;
  const newPPID = query.get('newPPID') ?? '';
  const description = query.get('description') ?? '';
  const redirect = query.get('redirect') ?? '';
  const couponCode = query.get('couponCode') ?? '';
  const planRecommendationId = query.get('planRecommendationId') ?? '';
  const shouldAnswerOnRecommendation = query.get('shouldAnswerOnRecommendation') === '1';

  const existingPlan = membershipPlans.find((plan) => plan._id === activePlanId);
  const newPlan = membershipPlans.find((plan) => plan._id === newPlanID);

  const newPricePoint = !newPlan
    ? null
    : getPricePoint(
        newPlan.pricePoints,
        newPlan.planCode === PlanCodes.FlexCare ? undefined : 3,
        newPPID
      );

  const handleGenerateDynamicLinkThen = (data: GenerateDynamicLinkResProps) => {
    setTimeout(() => window.open(data.data.dynamicLink, '_top'));
  };

  const handleGenerateDynamicLink = () => {
    generateDynamicLink({ accessToken, action: DynamicLinkActions.BILLING, src: 'appMobile' })
      .unwrap()
      .then(handleGenerateDynamicLinkThen)
      .catch(handleRequestCatch);
  };

  const onSuccessNavigate = (search = '') => {
    const planChangeWord = [
      PlanCodes.WeightManagementMembership,
      PlanCodes.UnlimitedMembership,
      PlanCodes.ConciergeMembership
    ].includes(newPlan?.planCode as PlanCodes)
      ? 'upgraded'
      : 'changed';
    notifyToast(`Your plan was ${planChangeWord}`, {
      icon: <Common.Icon name="info" />
    });
    if (redirect) {
      navigate(redirect, { replace: true });
    } else {
      navigate({ pathname: PathName.BillingDetails, search }, { replace: true });
    }
  };

  const handleUpdatedUserData = (data: GetMyAccountResProps) => {
    if (query.get('src') === 'appMobile') {
      handleGenerateDynamicLink();
      return;
    }

    const search = data.data.activePlanCode === PlanCodes.UnlimitedMembership ? '?m=unlimited' : '';
    dispatch(setUser(data.data));

    if (shouldAnswerOnRecommendation) {
      submitDecision({
        decision: 'accepted',
        planRecommendationId
      })
        .unwrap()
        .then(() => {
          dispatch(
            setUser({
              planRecommendation: {
                planRecommendationId: null
              }
            })
          );
          onSuccessNavigate(search);
        })
        .catch(handleRequestCatch);
    } else {
      onSuccessNavigate(search);
    }
  };

  const handleGetMyAccount = () => {
    getMyAccount().unwrap().then(handleUpdatedUserData).catch(handleRequestCatch);
  };

  const handleUpdateMyAccount = () => {
    updateMyAccount({
      ...(couponCode && { couponCode }),
      planId: newPlanID,
      planPricePointId: newPricePoint?.planPricePointId
    })
      .unwrap()
      .then(handleGetMyAccount)
      .catch(handleRequestCatch);
  };

  return (
    <>
      {!isLoading && !!newPlan && !!existingPlan && newPricePoint && (
        <PaymentCheckout
          category=""
          couponCode={couponCode}
          existingPlan={existingPlan}
          freeAppointmentInfo={{ isFree: true } as MembershipData['freeAppointmentInfo']}
          loading={
            isLoadingUpdateMyAccount ||
            isFetching ||
            isLoadingGeneratingLink ||
            isSendingRecommendationAnswer
          }
          newPlanSubtitle={description}
          providerName=""
          selectedPlan={newPlan}
          selectedPricePoint={newPricePoint}
          time=""
          isUpdatingRecommendation
          onProceed={handleUpdateMyAccount}
        />
      )}
    </>
  );
};

export default Checkout;
