import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import FlagProvider, { IConfig } from '@unleash/proxy-client-react';
import App from 'App';
import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from 'reportWebVitals';

import { persistor, store } from 'store';

import Loader from 'shared/Loader';

import { MessagesProvider } from 'contexts/messagesContext/messagesContext';
import socketMessages from 'socket/socketMessages';
import { appendScript } from 'utils/appendScript';
import { IS_LIVE } from 'utils/constants';

import 'config/i18n';
import 'chart.js/auto';
import 'utils/chartjsAdapterDayjs';

import { CommonSocketProvider } from './contexts/commonSocketContext/commonSocketContext';
import { initDatadog } from './utils/datadog';

import 'index.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-day-picker/dist/style.css';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(timezone);
dayjs.extend(advanced);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);

initDatadog();

IS_LIVE && appendScript(import.meta.env.BASE_URL + './js/equalWeb.js');

IS_LIVE &&
  getAnalytics(
    initializeApp({
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
    })
  );

if (import.meta.env.VITE_GTM_ID) {
  try {
    TagManager.initialize({ gtmId: import.meta.env.VITE_GTM_ID });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn((e as Error).message);
  }
}

const config: IConfig = {
  appName: 'LifeMD Patient Portal',
  clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY || '',
  environment: import.meta.env.VITE_UNLEASH_ENV,
  refreshInterval: 60,
  url: import.meta.env.VITE_UNLEASH_PROXY_URL || ''
};

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <FlagProvider config={config}>
      <Provider store={store}>
        <MessagesProvider socket={socketMessages}>
          <CommonSocketProvider socket={socketMessages}>
            <PersistGate loading={<Loader isVisible />} persistor={persistor}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </PersistGate>
          </CommonSocketProvider>
        </MessagesProvider>
      </Provider>
    </FlagProvider>
  </StrictMode>
);

reportWebVitals();
