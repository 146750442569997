import { PropsWithChildren } from 'react';
import SlidingPane from 'react-sliding-pane';
import { useLockBodyScroll } from 'react-use';
import { Common } from '@thecvlb/design-system';

import useWidth from 'hooks/useWidth';

import { SliderPanelProps } from './slidePanel.types';

const SliderPanel: React.FC<PropsWithChildren<SliderPanelProps>> = ({
  children,
  isShowModal,
  title,
  toggleModal,
  colorVariant = 'grey'
}) => {
  const isSecondary = colorVariant === 'secondary';
  const { isMobile } = useWidth();

  useLockBodyScroll(isShowModal && !isMobile);

  return (
    <SlidingPane
      className="!mt-0 !h-screen max-w-[550px]"
      from={isMobile ? 'bottom' : 'right'}
      isOpen={isShowModal}
      overlayClassName="z-[100]"
      width={!isMobile ? '40%' : '100%'}
      hideHeader
      onRequestClose={toggleModal}
    >
      <div className="flex items-start justify-between pt-8 max-md:px-4 md:p-8">
        <div className="grow">
          {typeof title === 'string' ? (
            <h2
              className={`grow text-m2xl font-bold md:text-xl ${isSecondary ? 'text-primary' : 'text-primary-700 md:text-gray-700'}`}
            >
              {title}
            </h2>
          ) : (
            title
          )}
        </div>

        <button className="ml-4" onClick={toggleModal}>
          <Common.Icon
            className={isSecondary ? 'text-primary' : 'text-primary-700 md:text-gray-700'}
            name="close"
          />
        </button>
      </div>
      <div className={`grow px-4 md:px-8 ${isSecondary ? 'bg-secondary-50' : 'bg-white'}`}>
        {children}
      </div>
    </SlidingPane>
  );
};

export default SliderPanel;
