import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { GoogleMapProps } from './googleMap.types';

const GoogleMap: React.FC<PropsWithChildren<GoogleMapProps>> = ({
  children,
  styles,
  onClick,
  onIdle,
  center,
  zoom,
  style,
  mapPoints,
  shouldBound,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (center) {
      map?.setCenter(center);
    }
  }, [center]);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          minZoom: 12,
          styles,
          zoom
        })
      );
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));
      if (onClick) {
        map.addListener('click', onClick);
      }
      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  useEffect(() => {
    if (mapPoints.length) {
      const bounds = new google.maps.LatLngBounds();
      mapPoints.forEach((p) => {
        if (p.latitude && p.longitude) {
          bounds.extend({ lat: p.latitude, lng: p.longitude });
        }
      });

      shouldBound && map?.fitBounds(bounds);
    }
  }, [mapPoints]);

  useDeepCompareEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement<{
            map: google.maps.Map;
          }>(child as React.ReactElement<never>, { map });
        }
      })}
    </>
  );
};

export default GoogleMap;
