import { PharmacyItem } from 'services/pharmacies/pharmacies.types';

import BakersSelected from "assets/icons/kroger-shops/selected/Baker's-Selected.svg";
import CityMarketSelected from 'assets/icons/kroger-shops/selected/City Market-Selected.svg';
import DillonsSelected from 'assets/icons/kroger-shops/selected/Dillons-Selected.svg';
import Food4LessSelected from 'assets/icons/kroger-shops/selected/Food 4 Less-Selected.svg';
import FoodsCoSelected from 'assets/icons/kroger-shops/selected/Foods Co-Selected.svg';
import FredMeyerSelected from 'assets/icons/kroger-shops/selected/Fred Meyer-Selected.svg';
import FrySelected from "assets/icons/kroger-shops/selected/Fry's-Selected.svg";
import GerbesSelected from 'assets/icons/kroger-shops/selected/Gerbes-Selected.svg';
import HarrisTeeterSelected from 'assets/icons/kroger-shops/selected/Harris Teeter-Selected.svg';
import JayCSelected from 'assets/icons/kroger-shops/selected/Jay C Food Store-Selected.svg';
import KingSoopersSelected from 'assets/icons/kroger-shops/selected/King Soopers-Selected.svg';
import KrogerSelected from 'assets/icons/kroger-shops/selected/Kroger-Selected.svg';
import MarianosSelected from "assets/icons/kroger-shops/selected/Mariano's-Selected.svg";
import MetroMarketSelected from 'assets/icons/kroger-shops/selected/Metro Market-Selected.svg';
import PayLessSelected from 'assets/icons/kroger-shops/selected/Pay-Less Super Markets-Selected.svg';
import PickNSaveSelected from "assets/icons/kroger-shops/selected/Pick'n Save-Selected.svg";
import QfcSelected from 'assets/icons/kroger-shops/selected/QFC-Selected.svg';
import RalphsSelected from "assets/icons/kroger-shops/selected/Ralph's-Selected.svg";
import RulerSelected from 'assets/icons/kroger-shops/selected/Ruler-Selected.svg';
import SmithsSelected from "assets/icons/kroger-shops/selected/Smith's Food and Drug-Selected.svg";
//regular brand icons
import Bakers from 'assets/icons/kroger-shops/Store=Baker’s.svg';
import CityMarket from 'assets/icons/kroger-shops/Store=City Market.svg';
import Dillons from 'assets/icons/kroger-shops/Store=Dillons.svg';
import Food4Less from 'assets/icons/kroger-shops/Store=Food 4 Less.svg';
import FoodsCo from 'assets/icons/kroger-shops/Store=Foods Co.svg';
import FredMeyer from 'assets/icons/kroger-shops/Store=Fred Meyer.svg';
import Fry from "assets/icons/kroger-shops/Store=Fry's.svg";
import Gerbes from 'assets/icons/kroger-shops/Store=Gerbes.svg';
import HarrisTeeter from 'assets/icons/kroger-shops/Store=Harris Teeter.svg';
import JayC from 'assets/icons/kroger-shops/Store=Jay C Food Store.svg';
import KingSoopers from 'assets/icons/kroger-shops/Store=King Soopers.svg';
import Kroger from 'assets/icons/kroger-shops/Store=Kroger.svg';
import Marianos from "assets/icons/kroger-shops/Store=Mariano's.svg";
import MetroMarket from 'assets/icons/kroger-shops/Store=Metro Market.svg';
import PayLess from 'assets/icons/kroger-shops/Store=Pay-Less Super Markets.svg';
import PickNSave from "assets/icons/kroger-shops/Store=Pick'n Save.svg";
import Qfc from 'assets/icons/kroger-shops/Store=QFC.svg';
import Ralphs from 'assets/icons/kroger-shops/Store=Ralphs.svg';
import Ruler from 'assets/icons/kroger-shops/Store=Ruler.svg';
import Smiths from "assets/icons/kroger-shops/Store=Smith's Food and Drug.svg";
import PointSvg from 'assets/images/map/point.svg';
import PointChosenSvg from 'assets/images/map/point-chosen.svg';
import PointChosenOpenSvg from 'assets/images/map/point-chosen-open.svg';
import PointOpenSvg from 'assets/images/map/point-open.svg';

type BrandKeys = keyof typeof brandsList;

export const getIcon = (
  pharmacy: PharmacyItem,
  isOpen: boolean,
  userPharmacy?: PharmacyItem,
  pharmacyId?: number,
  isMobile?: boolean
) => {
  try {
    const findIcon = () => {
      if (!!pharmacy.brand) {
        const brand = brandsList[pharmacy.brand as BrandKeys];
        return pharmacy.pharmacyId !== userPharmacy?.pharmacyId ? brand.icon : brand.iconSelected;
      }

      return pharmacyId === pharmacy.pharmacyId && isOpen
        ? pharmacy.pharmacyId === userPharmacy?.pharmacyId
          ? PointChosenOpenSvg
          : PointOpenSvg
        : pharmacy.pharmacyId === userPharmacy?.pharmacyId
          ? PointChosenSvg
          : PointSvg;
    };

    let size: number;
    if (pharmacyId === pharmacy.pharmacyId && isOpen) {
      size = isMobile ? 48 : 38;
    } else {
      size = isMobile ? 32 : 28;
    }

    if (!!pharmacy.brand) {
      size += 4;
    }

    if (google !== undefined && google.maps !== undefined) {
      return {
        scaledSize: new google.maps.Size(size, size),
        size: new google.maps.Size(size, size),
        url: findIcon()
      };
    }
    return {
      scaledSize: { width: size, height: size, equals: () => true },
      size: { width: size, height: size, equals: () => true },
      url: findIcon()
    };
  } catch {
    return {
      scaledSize: { width: 32, height: 32, equals: () => true },
      size: { width: 32, height: 32, equals: () => true },
      url: PointSvg
    };
  }
};

const brandsList = {
  fred_meyer: { icon: FredMeyer, iconSelected: FredMeyerSelected },
  frys: { icon: Fry, iconSelected: FrySelected },
  ralphs: { icon: Ralphs, iconSelected: RalphsSelected },
  king_soopers: { icon: KingSoopers, iconSelected: KingSoopersSelected },
  city_market: { icon: CityMarket, iconSelected: CityMarketSelected },
  kroger: { icon: Kroger, iconSelected: KrogerSelected },
  qfc: { icon: Qfc, iconSelected: QfcSelected },
  harris_teeter: { icon: HarrisTeeter, iconSelected: HarrisTeeterSelected },
  marianos: { icon: Marianos, iconSelected: MarianosSelected },
  payless: { icon: PayLess, iconSelected: PayLessSelected },
  jay_c: { icon: JayC, iconSelected: JayCSelected },
  dillon: { icon: Dillons, iconSelected: DillonsSelected },
  gerbes: { icon: Gerbes, iconSelected: GerbesSelected },
  bakers: { icon: Bakers, iconSelected: BakersSelected },
  metro_market: { icon: MetroMarket, iconSelected: MetroMarketSelected },
  pick_n_save: { icon: PickNSave, iconSelected: PickNSaveSelected },
  smiths: { icon: Smiths, iconSelected: SmithsSelected },
  // vitacost: { icon: Vitacost, iconSelected: 'https://kroger.com/' },
  ruler: { icon: Ruler, iconSelected: RulerSelected },
  food_4_less: { icon: Food4Less, iconSelected: Food4LessSelected },
  foods_co: { icon: FoodsCo, iconSelected: FoodsCoSelected }
} as const;
