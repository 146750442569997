import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { AT_LEAST_LOWER_OR_UPPER_CASE, AT_LEAST_ONE_NUMBER_OR_SPEC_CHAR } from 'utils/regExp';

const NewPasswordInput = <TFormValues extends FieldValues>({
  name,
  control,
  placeholder = 'New password',
  label,
  labelClassName,
  size
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name,
    rules: {
      validate: {
        minLength: (value) => value.length >= 8,
        specChar: (value) => AT_LEAST_ONE_NUMBER_OR_SPEC_CHAR.test(value),
        lowerUpperCase: (value) => AT_LEAST_LOWER_OR_UPPER_CASE.test(value)
      }
    }
  });

  const newPasswordIsDirty = fieldState.isDirty;
  const isMinLength = field.value.length >= 8 && newPasswordIsDirty;
  const isSpecChar = AT_LEAST_ONE_NUMBER_OR_SPEC_CHAR.test(field.value) && newPasswordIsDirty;
  const isLowerUppercase = AT_LEAST_LOWER_OR_UPPER_CASE.test(field.value) && newPasswordIsDirty;

  const getClassName = (isValid: boolean) => {
    return classNames(
      'flex items-center gap-1 text-mSm md:text-sm',
      isValid ? 'text-green' : newPasswordIsDirty ? 'text-red' : 'text-gray'
    );
  };

  return (
    <div className="w-full">
      <Common.Input
        dataTestId="password_field"
        {...field}
        errors={fieldState.error}
        inputClassName="text-gray-700"
        label={label}
        labelClassName={labelClassName}
        placeholder={placeholder}
        size={size}
        type="password"
      />
      <div className="mt-4 flex flex-col gap-1 md:mt-2" data-testid="password_validation_msgs">
        <p className={getClassName(isLowerUppercase)} data-testid={`green=_${isLowerUppercase}`}>
          {isLowerUppercase && <Common.Icon className="size-4" name="check" />}
          Includes at least 1 lowercase or uppercase character
        </p>
        <p className={getClassName(isSpecChar)} data-testid={`green=_${isSpecChar}`}>
          {isSpecChar && <Common.Icon className="size-4" name="check" />}
          Includes a number or special character
        </p>
        <p className={getClassName(isMinLength)} data-testid={`green=_${isMinLength}`}>
          {isMinLength && <Common.Icon className="size-4" name="check" />}
          At least 8 characters
        </p>
      </div>
    </div>
  );
};

export default NewPasswordInput;
