import { SexAtBirth } from 'utils/enums';

import { InsuranceItem } from 'models/insurance.types';

export const MIGRATE_TO_WM_FLOW_STEPS = [
  'wm-weight-lose-without-uncertainty',
  'wm-weight-lose-aim',
  'wm-user-health-state',
  'wm-diabetes-question',
  'wm-you-are-in-good-hands',
  'height-and-weight',
  'wm-new-target-weight',
  'wm-insurance',
  'wm-congrats',
  'pregnancy',
  'wm-pancreatitis-question',
  'wm-thyroid-nodules-question',
  'wm-gallbladder-question',
  'wm-gallstones-question',
  'wm-medical-conditions',
  'pre-insurance',
  'lower-price',
  'insurance',
  'verify-identity',
  'wm-select-plan',
  'wm-schedule-appointment',
  'appointment-confirmation',
  'wm-checkout',
  'wm-complete-onboarding'
] as const;

export type PossibleStepsType = typeof MIGRATE_TO_WM_FLOW_STEPS;
export type StepName = PossibleStepsType[number];

export type ParamsToDefineFlowSteps = {
  activePlanCode: string;
  hasInsurance: boolean | null;
  hasUncompletedSession?: boolean;
  identityVerified: boolean;
  insurances: InsuranceItem[];
  isAsyncPlan?: boolean;
  isFirstAppointmentCompleted: boolean;
  sexAtBirth: SexAtBirth | '';
};

export type MoveToStepFunction = (
  v: 'prev' | 'next' | StepName | { answer: boolean | string; step: StepName },
  extraSearch?: string
) => void;

export type ContentProps = {
  loading: boolean;
  moveToStep: MoveToStepFunction;
  onSelectInsurance: (type: boolean) => Promise<unknown>;
  step: StepName;
  steps: StepName[];
};
