import { isMobile } from 'react-device-detect';
import { toast, ToastOptions } from 'react-toastify';
import { Common } from '@thecvlb/design-system';

export const notifyError = (
  message: string,
  showAsSnackbar = false,
  customOptions?: ToastOptions
) =>
  toast.error(message, {
    closeOnClick: true,
    icon: <Common.Icon className="size-7 text-red" name="error" />,
    progressClassName: 'bg-red',
    ...(showAsSnackbar && { position: isMobile ? 'bottom-center' : 'top-center' }),
    ...customOptions
  });

export const notifySuccess = (
  message: string,
  showAsSnackbar = false,
  customOptions?: ToastOptions
) =>
  toast.success(message, {
    closeOnClick: true,
    icon: <Common.Icon className="size-7 text-secondary" name="check" />,
    progressClassName: 'bg-secondary',
    ...(showAsSnackbar && { position: isMobile ? 'bottom-center' : 'top-center' }),
    ...customOptions
  });

// aka 'snackbar'
export const notifyToast = (
  message: string,
  options?: ToastOptions,
  onBtnClick?: (...args: unknown[]) => void,
  btnText = ''
) =>
  toast.info(
    <div className="mr-4 flex items-center gap-4 bg-gray-800">
      <h4 className="text-mSm text-white md:text-sm">{message}</h4>
      {!!onBtnClick && (
        <button className="min-w-fit text-secondary" onClick={onBtnClick}>
          {btnText}
        </button>
      )}
    </div>,
    {
      icon: false,
      position: isMobile ? 'bottom-center' : 'top-center',
      progressClassName: 'bg-secondary',
      theme: 'dark',
      ...options
    }
  );
