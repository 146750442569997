import { Common } from '@thecvlb/design-system/lib/src';

import { AppointmentProps } from './appointment.types';

const Appointment: React.FC<AppointmentProps> = ({ onContinue }) => {
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-col items-center gap-2 py-6 text-center">
        <Common.Illustration name="calendar" />
        <h4 className="wm-signup-title">
          You'll set up an appointment at the time of requesting your next prescription renewal.
        </h4>
        <span>
          You can discuss your medication options with your provider before receiving your next
          dose.
        </span>
      </div>
      <Common.Button
        className="max-md:mt-auto md:mx-auto"
        color="blue"
        fullWidthOnMobile
        onClick={onContinue}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default Appointment;
