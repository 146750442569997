import { Common } from '@thecvlb/design-system/lib/src';
import HTMLReactParser from 'html-react-parser';

import { InfoScreenProps } from './infoScreen.types';

const InfoScreen: React.FC<InfoScreenProps> = ({ onClickContinue, config }) => {
  return (
    <div className="flex size-full flex-col items-center gap-8">
      <div className="rounded-full bg-secondary-100 p-4 md:p-8">
        <Common.Icon className="size-[60px] text-secondary-600 md:size-[48px]" name="patient" />
      </div>
      <div className="flex flex-col items-center gap-2 text-center text-primary-700 md:gap-4">
        <h3 className="wm-signup-title">
          {HTMLReactParser(config.title ?? 'We need additional information about you')}
        </h3>
        <span className="flex flex-col gap-1 md:max-w-[374px] md:font-semibold">
          {HTMLReactParser(config.description ?? '')}
        </span>
      </div>
      <Common.Button
        className="max-md:mt-auto"
        color="blue"
        fullWidthOnMobile
        onClick={onClickContinue}
      >
        Get started
      </Common.Button>
    </div>
  );
};

export default InfoScreen;
