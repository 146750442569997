import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { useDelayedScroll } from 'hooks/useDelayedScroll';
import { getDateValue } from 'utils/helpers';

import { DataProps, PickerProps } from './picker.types';

const Picker: React.FC<PickerProps> = ({
  data,
  onSelect,
  isLoading,
  date,
  onConfirm,
  hideDate = false,
  setProvider,
  provider = 'my',
  isReschedule,
  isUnlimitedPlan
}) => {
  const [selectedTime, setSelectedTime] = useState<string | Date>('');
  const [, updateState] = useState<{ ['key']?: string }>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const scrollBlock = useRef<HTMLDivElement>(null);
  const myRef = useRef<HTMLDivElement>(null);
  const scrollStopper = useDelayedScroll(forceUpdate, 200);

  const intersection = useIntersection(myRef as React.RefObject<HTMLElement>, {
    root: null,
    rootMargin: '0px',
    threshold: 1
  });

  const getSlotClassNames = (item: DataProps) =>
    classNames(
      'py-5 md:py-4 w-full !leading-3 border rounded-lg',
      !isLoading ? 'hover:bg-primary-100' : 'opacity-50',
      selectedTime === item.value ? 'bg-primary-100 border-primary-300 font-bold' : 'bg-transparent'
    );

  const confirmButtonClassName = (value: string | Date) =>
    classNames(
      { 'opacity-50': isLoading },
      selectedTime === value ? 'visible w-full ml-2' : 'invisible w-0',
      'py-[18px] md:py-3.5 border transition-all duration-300 text-white rounded-lg font-bold bg-primary border-primary'
    );

  const handleClickSlot = (dataItem: DataProps) => {
    setSelectedTime(dataItem.value === selectedTime ? '' : dataItem.value);
  };

  const handleClickSeeAnyProvider = () => {
    setProvider?.('any');
  };

  useEffect(() => {
    const item = data.find((slot) => slot.value === selectedTime);
    if (item) {
      onSelect(item.value, item.valueEnd, item?.doctorId, item?.displayName);
    }
  }, [selectedTime]);

  return (
    <div className="relative mx-auto max-h-full min-h-40 w-full overflow-hidden">
      {!hideDate && (
        <p className="mb-3 text-center text-mSm text-gray md:text-sm" data-testid="selected_date">
          {getDateValue(date)}
        </p>
      )}
      {data.length ? (
        <div
          className="flex max-h-[45vh] flex-col gap-y-3 overflow-auto overflow-x-hidden md:h-[280px] md:gap-y-2"
          data-testid="time_select_area"
          ref={scrollBlock}
          onScroll={scrollStopper}
        >
          {data.map((dataItem: DataProps, i) => (
            <div
              className="flex justify-center"
              key={dataItem.value}
              ref={i === data.length - 2 ? myRef : undefined}
            >
              <button
                className={getSlotClassNames(dataItem)}
                data-testid="time"
                disabled={isLoading}
                type="button"
                onClick={() => handleClickSlot(dataItem)}
              >
                {dataItem.label}
              </button>
              <button
                className={confirmButtonClassName(dataItem.value)}
                disabled={isLoading}
                type="button"
                onClick={onConfirm}
              >
                {isReschedule ? 'Reschedule' : isUnlimitedPlan ? 'Confirm' : 'Continue'}
              </button>
            </div>
          ))}
          {!intersection?.isIntersecting && data.length > 5 && (
            <div className="slots-after-layer"></div>
          )}
        </div>
      ) : (
        !isLoading && (
          <>
            <span
              className="mt-4 block text-center italic text-gray"
              data-testid="not_available_provider"
            >
              {provider === 'my'
                ? 'Your provider is unavailable for the selected day.'
                : 'There are no available providers for the selected day.'}
            </span>
            {!!date && setProvider && provider !== 'any' && (
              <Common.Button
                className="mx-auto mt-4"
                color="blue-alt"
                onClick={handleClickSeeAnyProvider}
              >
                See any available provider
              </Common.Button>
            )}
          </>
        )
      )}
    </div>
  );
};

export default Picker;
