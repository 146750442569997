import { useEffect, useRef, useState } from 'react';
import { Modifiers } from 'react-day-picker';
import { toast } from 'react-toastify';
import { useClickAway, useSessionStorage, useTitle } from 'react-use';
import dayjs from 'dayjs';

import {
  useLazyGetAvailableDatesQuery as useLazyGetGlobalAvailableDatesQuery,
  useLazyGetGuestCombinedAvailabilityQuery,
  useReleaseSlotsMutation
} from 'services/auth/auth';
import { useGetAppointmentTypesQuery } from 'services/lookup/lookup';
import { useLazyGetCombinedAvailabilityQuery } from 'services/providers/providers';
import {
  CombinedTimeSlots,
  GetAvailableDatesResProps,
  GetAvailableTimesResProps,
  GetAvailableTimesSlotsItemProps
} from 'services/providers/providers.types';

import { selectLookup, selectOrchestrate, selectSignUp, selectUser } from 'store';
import { AppointmentProps } from 'store/appointments/appointments.types';

import { MixedSignupStepProps } from 'containers/SignUp/Content/content.types';
import Calendar from 'features/Calendar';
import Picker from 'features/Picker';
import Loader from 'shared/Loader';
import { notifyError } from 'shared/Toast/Toast';
import SchedulingDaySlider from 'widgets/SchedulingDaySlider';
import { getDaysList } from 'widgets/SchedulingDaySlider/schedulingDaySlider.settings';

import {
  DEFAULT_OPTAVIA_APPOINTMENT_TYPE,
  DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE
} from 'constants/defaults';
import { useAppSelector } from 'hooks';
import { useGetInitialDatesWithSlots } from 'hooks/useGetInitialDatesWithSlots';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import useWidth from 'hooks/useWidth';
import { DateFormat, FlowTypes, PlanCodes } from 'utils/enums';
import { findAppointmentType, findDisabledDates, handleRequestCatch } from 'utils/helpers';

import Heading from '../parts/Heading';

const startMonth = new Date();

const ScheduleAnAppointment: React.FC<MixedSignupStepProps> = ({ moveToStep, selectedFlow }) => {
  const timezone = dayjs.tz?.guess();

  useTitle('Schedule an appointment');

  const { send, isLoading } = useSubmitOrchestrateForm();
  const [releaseSlots] = useReleaseSlotsMutation();

  const [, setHoldTime] = useSessionStorage<string>('hold-time');
  const [sessionId] = useSessionStorage<string>('session-id', '');

  const { data: appointmentTypes, isFetching } = useGetAppointmentTypesQuery({
    ...(selectedFlow === FlowTypes.WeightManagementFlowOptavia && { partner: 'Optavia' }),
    ...(['tt', 'authorized'].includes(selectedFlow) && {
      patientMembershipPlan: PlanCodes.WeightManagementMembership
    })
  });
  const [getGlobalDates, { isFetching: isGettingGlobalDates }] =
    useLazyGetGlobalAvailableDatesQuery();

  const {
    user: { planId }
  } = useAppSelector(selectSignUp);
  const {
    user: { state, accessToken },
    payment: { product_price_point_handle: productPricePointHandle }
  } = useAppSelector(selectOrchestrate);
  const { state: loggedInUserState, accessToken: authorizedUserAccessToken } =
    useAppSelector(selectUser);
  const { membershipPlans } = useAppSelector(selectLookup);

  const ref = useRef(null);

  const [getCombinedData, { isFetching: isTimesLoading }] =
    useLazyGetGuestCombinedAvailabilityQuery();

  // combined slots per day for authorized users
  const [getCombinedSlots, { isFetching: isFetchingSlotsForAuthorizedUsers }] =
    useLazyGetCombinedAvailabilityQuery();

  const { isMobile } = useWidth();
  const typeBaseOnFlow =
    selectedFlow === FlowTypes.WeightManagementFlowOptavia
      ? DEFAULT_OPTAVIA_APPOINTMENT_TYPE
      : DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE;

  const WM_APPOINTMENT_TYPE_ID =
    findAppointmentType(typeBaseOnFlow, appointmentTypes?.data)?._id ?? '';
  const FALLBACK_PLAN_CODE = PlanCodes.WeightManagementMembership;
  const FALLBACK_PLAN_ID =
    membershipPlans.find((plan) => plan.planCode === FALLBACK_PLAN_CODE)?._id ?? '';

  const { anyProvidersFreeDates, anyProvidersDisabledDates, loading } = useGetInitialDatesWithSlots(
    {
      excludeMyProvider: false,
      accessToken: authorizedUserAccessToken || accessToken || '',
      planPricePointId: productPricePointHandle,
      appointmentTypeId: WM_APPOINTMENT_TYPE_ID,
      planId: planId || FALLBACK_PLAN_ID,
      providers: ['any'],
      state: state || loggedInUserState,
      flow: selectedFlow,
      isAuthorized: !!authorizedUserAccessToken,
      callbackOnError: (error) => {
        // fix for token expired. We need to refresh it via sending dummy 'empty' request and then move back, so next time user will have valid accessToken
        if ('status' in error && error.status === 401) {
          send('mif_qa', [], () => {
            notifyError('Your session has expired. Please try again.');
            moveToStep('prev');
          });
        }
      }
    }
  );

  const getDates = () => anyProvidersFreeDates.slice(0, 7);

  const datesList = getDaysList(getDates(), true, false);

  const [disabledDates, setDisabledDates] = useState<(string | { from: Date; to: Date })[]>([]);
  const [isShowCalendar, toggleShowCalendar] = useState(false);
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [slotsData, setSlotsData] = useState<
    {
      displayName: string;
      doctorId: string;
      label: string;
      value: string;
      valueEnd: string;
    }[]
  >([]);
  const [timeSlots, setTimeSlots] = useState<
    (GetAvailableTimesSlotsItemProps | CombinedTimeSlots)[] | undefined
  >();

  const [appointmentData, setAppointmentData] = useState<
    Partial<AppointmentProps> & { appointmentMethod: 'video' | 'audio' }
  >({
    appointmentMethod: 'video',
    doctorId: '',
    endTime: '',
    isWeightManagementAppointment: true,
    startTime: ''
  });

  const isCombinedLoading =
    isTimesLoading || loading || isLoading || isFetching || isFetchingSlotsForAuthorizedUsers;

  useClickAway(ref, () => {
    setTimeout(() => isShowCalendar && toggleShowCalendar(false), 100);
  });

  const handleSelect = (v: string) => {
    if (v === 'calendar') {
      toggleShowCalendar(!isShowCalendar);
    } else {
      toggleShowCalendar(false);
      setSelectedDay(v);
    }
  };

  const handleDayClick = (day: Date, modifiers: Modifiers) => {
    if (modifiers.disabled || !day) {
      return;
    }
    setSelectedDay(dayjs(day).format(DateFormat.YYYY_MM_DD));
    toggleShowCalendar(false);
  };

  const onTimeSelect = (value: string, valueEnd: string, doctorId?: string) => {
    setAppointmentData((prev) => ({
      ...prev,
      appointmentTypeId: WM_APPOINTMENT_TYPE_ID,
      doctorId,
      endTime: valueEnd,
      startTime: value
    }));
  };

  const handleGetAvailableTimesThen = ({ data }: GetAvailableTimesResProps) =>
    setTimeSlots(data.slots);

  const handleGetAvailableTimes = () => {
    if (!!authorizedUserAccessToken) {
      getCombinedSlots({
        date: dayjs(selectedDay.toString()).format(DateFormat.YYYY_MM_DD),
        appointmentTypeId: WM_APPOINTMENT_TYPE_ID,
        timezone,
        planId: planId || FALLBACK_PLAN_ID,
        planPricePointId: productPricePointHandle
      })
        .unwrap()
        .then(handleGetAvailableTimesThen)
        .catch(handleRequestCatch);
    } else {
      getCombinedData({
        ...(selectedFlow === FlowTypes.WeightManagementFlowOptavia && {
          onboardingPartnerName: 'Optavia'
        }),
        accessToken: accessToken || '',
        appointmentDate: dayjs(selectedDay.toString()).format(DateFormat.YYYY_MM_DD),
        appointmentTypeId: WM_APPOINTMENT_TYPE_ID,
        doctorLicenseState: state || loggedInUserState || 'California',
        patientTimezone: timezone,
        planId: planId || FALLBACK_PLAN_ID,
        sessionId,
        planPricePointId: productPricePointHandle
      })
        .unwrap()
        .then(handleGetAvailableTimesThen)
        .catch(handleRequestCatch);
    }
  };

  const scheduleNewAppointment = () => {
    try {
      if (appointmentData) {
        send(
          'appointment',
          {
            ...appointmentData,
            appointmentTime: {
              endTime: appointmentData.endTime ?? '',
              startTime: appointmentData.startTime ?? ''
            },
            planId: planId || FALLBACK_PLAN_ID,
            planPricePointId: productPricePointHandle,
            timezone
          },
          () => {
            setHoldTime(dayjs().add(10, 'minutes').toString());
            moveToStep(
              'next',
              selectedFlow === 'authorized'
                ? {
                    searchParams: `newPlanID=${FALLBACK_PLAN_ID}&newPPID=${productPricePointHandle}`
                  }
                : undefined
            );
          },
          undefined,
          (v: unknown) => {
            const casted = v as { data: { message: string } };
            if (
              casted?.data?.message &&
              casted.data.message === 'The slot is no longer available'
            ) {
              handleGetAvailableTimes();
            }
          }
        );
      } else {
        throw new Error('Something went wrong. Please try again later.');
      }
    } catch (e) {
      toast.warn((e as Error).message);
    }
  };

  const handleUpdatedTimeSlots = () => {
    setSlotsData(
      Array.isArray(timeSlots)
        ? timeSlots.map(({ startTime, endTime, ...rest }) => ({
            displayName: (rest as CombinedTimeSlots)?.displayName,
            doctorId: (rest as CombinedTimeSlots)?.doctorId,
            label: `${dayjs(startTime).format(DateFormat.hh_mma_z)}`,
            value: startTime,
            valueEnd: endTime
          }))
        : []
    );
  };

  const handleGetTimes = () => {
    WM_APPOINTMENT_TYPE_ID && selectedDay && handleGetAvailableTimes();
  };

  const handleGetAvailableDatesThen = (
    res: GetAvailableDatesResProps,
    startDate: string,
    endDate: string
  ) => {
    const daysWithNoSlots = findDisabledDates(
      res.data.map((e) => e.date),
      startDate,
      endDate
    );
    setDisabledDates(daysWithNoSlots);
  };

  const getDaysWithSlots = (date: Date) => {
    const formattedDate = dayjs(date).isBefore(dayjs()) ? dayjs() : dayjs(date);
    const previousDisabledDates = disabledDates;
    setDisabledDates([
      {
        from: formattedDate.toDate(),
        to: dayjs(formattedDate).endOf('month').toDate()
      }
    ]);

    getGlobalDates({
      ...(selectedFlow === FlowTypes.WeightManagementFlowOptavia && {
        onboardingPartnerName: 'Optavia'
      }),
      accessToken: authorizedUserAccessToken || accessToken || '',
      appointmentTypeId: WM_APPOINTMENT_TYPE_ID,
      endDate: dayjs(formattedDate).endOf('month').format(DateFormat.YYYY_MM_DD),
      planId: planId || FALLBACK_PLAN_ID,
      startDate: formattedDate.format(DateFormat.YYYY_MM_DD),
      state,
      timezone,
      planPricePointId: productPricePointHandle
    })
      .unwrap()
      .then((r) =>
        handleGetAvailableDatesThen(
          r,
          formattedDate.format(DateFormat.YYYY_MM_DD),
          dayjs(formattedDate).endOf('month').format(DateFormat.YYYY_MM_DD)
        )
      )
      .catch(() => {
        setDisabledDates(previousDisabledDates);
      });
  };
  const getDisabledDates = () => {
    return [
      { before: dayjs().add(1, 'days').toDate() },
      ...[...anyProvidersDisabledDates, ...disabledDates].map((d) => {
        return typeof d === 'string' ? dayjs(d).toDate() : d;
      })
    ];
  };

  const calendarWrapperClassName =
    'relative max-w-full flex max-h-min flex-col justify-center gap-8 md:gap-6 md:min-w-[500px]';
  const calendarRefClassName =
    'absolute right-0 top-[88px] z-10 hidden rounded-xl bg-white p-4 shadow-lg md:block';

  useEffect(handleGetTimes, [selectedDay, WM_APPOINTMENT_TYPE_ID]);
  useEffect(handleUpdatedTimeSlots, [timeSlots]);
  useEffect(() => {
    sessionId && releaseSlots(sessionId);
  }, []);
  useEffect(() => {
    if (anyProvidersFreeDates.length) {
      setSelectedDay(getDates()?.[0]);
    }
  }, [anyProvidersFreeDates]);

  return (
    <div className="flex flex-col place-items-center gap-6">
      <Loader isVisible={isCombinedLoading} />
      <Heading
        category="Sign up"
        subtitle={
          selectedFlow === FlowTypes.TripleTherapy ? (
            <span>
              Select a date and time to discuss your health history and weight loss goals with a
              provider on video. They’ll use this information to create your treatment plan with a
              combination of weight loss medications including{' '}
              <span className="font-bold">Metformin, Bupropion and Topiramate.</span>
            </span>
          ) : selectedFlow === FlowTypes.BlueLineFlow ? (
            <span>
              Select a date and time to discuss your health history and weight loss goals with a
              provider on video. They’ll use this information to create your treatment plan with a
              GLP-1 medication - if appropriate for you.
            </span>
          ) : selectedFlow === FlowTypes.WeightManagementBalladHealth ? (
            <span>
              Select a date and time to discuss your health history and weight loss goals with a
              provider on video. They’ll use this information to create your treatment plan with a
              GLP-1 medication.
            </span>
          ) : (
            <span>
              Select a date and time to discuss your health history and weight loss goals with a
              provider on video. They’ll use this information to create your treatment plan with a
              GLP-1 medication such as{' '}
              <span className="font-bold">Wegovy®, Zepbound® or Ozempic®</span> - if appropriate
              for you.
            </span>
          )
        }
        title={`Schedule your first ${selectedFlow === FlowTypes.TripleTherapy ? 'Triple Therapy' : selectedFlow === 'authorized' ? 'Weight Management' : ''} appointment.`}
      />
      {!loading && (
        <div className={calendarWrapperClassName} data-testid="day_slide_picker">
          <div>
            <SchedulingDaySlider
              additionalDisabledDates={getDisabledDates()}
              dates={datesList}
              isAsapAvailable={false}
              isCalendarSelected={isShowCalendar}
              loading={isCombinedLoading}
              params={{
                ...(selectedFlow === FlowTypes.WeightManagementFlowOptavia && {
                  onboardingPartnerName: 'Optavia'
                }),
                excludeMyProvider: false,
                appointmentTypeId: WM_APPOINTMENT_TYPE_ID,
                planId,
                provider: 'any',
                state
              }}
              selected={selectedDay}
              onSelect={handleSelect}
            />
          </div>
          {isShowCalendar && !isMobile && (
            <div className={calendarRefClassName} ref={ref}>
              <Calendar
                disabled={getDisabledDates()}
                loading={isGettingGlobalDates}
                selected={dayjs(selectedDay).toDate()}
                startMonth={startMonth}
                onDayClick={handleDayClick}
                onMonthChange={getDaysWithSlots}
              />
            </div>
          )}
          {datesList.length > 0 && (
            <Picker
              data={slotsData}
              date={selectedDay}
              isLoading={isCombinedLoading}
              isUnlimitedPlan={false}
              provider="any"
              onConfirm={scheduleNewAppointment}
              onSelect={onTimeSelect}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ScheduleAnAppointment;
