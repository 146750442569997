import { SexAtBirth } from 'utils/enums';

import { ParamsToDefineFlowSteps, PossibleStepsType, StepName } from './migrateToWM.types';

export const defineFlowSteps = (
  steps: PossibleStepsType,
  params: ParamsToDefineFlowSteps
): StepName[] => {
  let copySteps: StepName[] = [...steps];
  const stepsToFilter: StepName[] = [];

  // Check different conditions to filter steps by adding steps to filter array in the end
  // 1. If user had not completed onboarding, we remove all steps before Sex at Birth step
  if (params.hasUncompletedSession) {
    stepsToFilter.push(
      'wm-weight-lose-without-uncertainty',
      'wm-weight-lose-aim',
      'wm-user-health-state',
      'wm-diabetes-question',
      'wm-you-are-in-good-hands',
      'height-and-weight',
      'wm-new-target-weight',
      'wm-insurance',
      'wm-congrats'
    );
  }
  // 2. Remove pregnancy step for non female patients
  if (params.sexAtBirth !== SexAtBirth.Female) {
    stepsToFilter.push('pregnancy');
  }
  // 3. If user has insurance or selected 'no', we remove pre-insurance and insurance steps

  if (
    params.isAsyncPlan ||
    (params.hasInsurance && params.insurances.length > 0) ||
    params.hasInsurance === false
  ) {
    stepsToFilter.push('pre-insurance', 'insurance', 'wm-insurance', 'lower-price');
  }

  // 4. If user is verified by Vouched or doesn't have an active plan (means he is freemium user), we remove all steps related to identity verification
  if (params.identityVerified || !params.activePlanCode) {
    stepsToFilter.push('verify-identity');
  }

  copySteps = copySteps.filter((step) => !stepsToFilter.includes(step));

  return copySteps;
};
