import { JSX, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';

import ExpandablePanel from 'shared/ExpandablePanel';

import { parseText } from 'utils/parseText';

const WMQuestionsList: React.FC<{
  questions: {
    id: string;
    text: JSX.Element | string;
    title: string;
  }[];
}> = ({ questions }) => {
  const [activeElement, setActiveElement] = useState('0');

  return (
    <div className="flex flex-col divide-y">
      {questions.map((page) => {
        return (
          <ExpandablePanel
            className="py-4"
            content={
              typeof page.text !== 'string' ? (
                <p className="text-gray-700">{page.text}</p>
              ) : (
                <div>{HTMLReactParser(parseText(page.text))}</div>
              )
            }
            header={
              <div className="flex cursor-pointer justify-between">
                <h3 className="text-mBase font-bold text-primary-700 md:text-base">{page.title}</h3>
                <Common.Icon
                  className={classNames(
                    'ml-1.5 mt-1 size-5 flex-none transition-all duration-300 md:h-4 md:w-4',
                    { 'rotate-90': page.id === activeElement }
                  )}
                  name="arrow-right"
                />
              </div>
            }
            isShowContent={page.id === activeElement}
            key={page.id}
            toggleContent={() => setActiveElement(page.id === activeElement ? '0' : page.id)}
          />
        );
      })}
    </div>
  );
};

export default WMQuestionsList;
