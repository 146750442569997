import { Common } from '@thecvlb/design-system';

import { MixedSignupStepProps } from 'containers/SignUp/Content/content.types';

import { FlowTypes } from 'utils/enums';

const Congrats: React.FC<MixedSignupStepProps> = ({ moveToStep, selectedFlow }) => {
  return (
    <div className="flex h-full flex-col items-center gap-6">
      <Common.Illustration name="success" />
      <div className="text-center">
        <h2 className="wm-signup-title mb-2" data-testid="header">
          Congratulations!
        </h2>

        <p className="max-w-[370px] text-primary-700" data-testid="sub_header">
          {selectedFlow !== FlowTypes.TripleTherapy
            ? 'You’ve pre-qualified for GLP-1 medication'
            : 'You’ve pre-qualified for the LifeMD Weight Management Triple Therapy Program.'}
        </p>
      </div>
      {selectedFlow === FlowTypes.WeightManagementFlowInsuranceAlt ||
      selectedFlow === FlowTypes.WeightManagementFlowInsuranceDiscovery ? (
        <Common.Alert type="info" colorableBackground>
          Next, let’s complete your medical intake form and schedule your appointment.
        </Common.Alert>
      ) : (
        <div className="flex items-center gap-3 rounded-lg bg-secondary-100 p-4">
          <Common.Icon className="flex-none text-secondary" name="stethoscope" />
          <div className="flex flex-col gap-3">
            {selectedFlow !== FlowTypes.TripleTherapy && (
              <p>
                {selectedFlow === FlowTypes.BlueLineFlow ? (
                  'Your answers indicate that you may be suitable for a GLP-1 prescription'
                ) : (
                  <>
                    Your answers indicate that you may be suitable for a GLP-1 prescription such as{' '}
                    <span className="font-bold">Wegovy® </span>
                    or <span className="font-bold">Ozempic®</span> — which could also be fully or
                    partially covered by your insurance provider.
                  </>
                )}
              </p>
            )}
            <p className="font-bold text-primary">Next, let’s complete your medical intake form.</p>
          </div>
        </div>
      )}
      {selectedFlow !== 'authorized' &&
        [
          FlowTypes.WeightManagementFlowInsurance,
          FlowTypes.WeightManagementFlowInsuranceMedications,
          FlowTypes.WeightManagementBalladHealth
        ].includes(selectedFlow) && (
          <p>
            If your insurance doesn't cover the cost of your medication, enrolling in the LifeMD
            Weight Management Program includes access to the LifeMD Weight Management Program, you
            gain access to compounded GLP-1 medications where you can start treatment for as little
            as $13 a week.
          </p>
        )}
      <Common.Button
        className="max-md:mt-auto"
        color="blue"
        fullWidthOnMobile
        onClick={() => moveToStep('next')}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default Congrats;
