import { useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import ShopOrderDetails from 'modals/ShopOrderDetails';

import { TrackerProps } from './tracker.types';

const Tracker: React.FC<TrackerProps> = ({ orders }) => {
  const [isOpenShopOrderDetails, toggleIsOpenShopOrderDetails] = useToggle(false);
  const [selectedOrder, setSelectedOrder] = useState<number>(0);

  const handleOpenOrder = (index: number) => {
    setSelectedOrder(index);
    toggleIsOpenShopOrderDetails();
  };

  const isMultipleOrders = orders?.length > 1;
  const heading = `Your ${isMultipleOrders ? 'products are' : `${orders?.[0].items[0]?.product?.name} is`} on their way!`;
  return (
    <>
      <ShopOrderDetails
        data={orders[selectedOrder]}
        isOpen={isOpenShopOrderDetails}
        onClose={toggleIsOpenShopOrderDetails}
      />
      <div className="flex flex-col gap-4 rounded-2xl bg-secondary-100 p-4">
        <h2 className="font-bold text-primary-700">{heading}</h2>
        {orders.map((order, idx) => {
          const isLabKit = order.items[0].product?.type?.toLowerCase().includes('lab kit');
          return (
            <button
              className="flex items-center gap-4 rounded-2xl border border-black/10 bg-white p-2 px-4 shadow-sm"
              data-testid="product_item"
              key={order._id}
              onClick={() => handleOpenOrder(idx)}
            >
              <div className="flex gap-4">
                {isLabKit && (
                  <img
                    alt={order.items[0].product.name}
                    className="mx-auto h-[63px] max-w-full object-contain transition-all group-hover:scale-[102%]"
                    data-testid="product_img"
                    src={order.items[0].product?.featuredAsset?.url}
                  />
                )}
                <div className="flex flex-col items-start justify-center">
                  <h4 className="text-mBase font-bold">
                    {isLabKit ? order?.items?.[0].product.name : `Order #: ${order.orderNumber}`}
                  </h4>
                  <p className="text-mSm text-gray">
                    {isLabKit ? 'Lab kit is shipping' : order.shippingStatus || 'Ordered'}
                  </p>
                </div>
              </div>
              <Common.Icon className="ml-auto size-5 flex-none text-gray" name="arrow-right" />
            </button>
          );
        })}
      </div>
    </>
  );
};

export default Tracker;
