import { Common } from '@thecvlb/design-system/lib/src';

import { IntroProps } from './intro.types';

import tirzepatide from 'assets/images/medications/tirzepatide-square.webp';

const Intro: React.FC<IntroProps> = ({ onContinue }) => {
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-col items-center gap-2 py-6 text-center">
        <div>
          <img
            alt="tirzepatide"
            className="size-[96px] rounded-full object-contain"
            src={tirzepatide}
          />
        </div>
        <h4 className="text-m2xl font-bold text-primary-700 md:text-2xl">Heads up!</h4>
        <span>
          The FDA has removed the tirzepatide injection from its Drug Shortages List, impacting
          compounded medication options for patients.
        </span>
        <span>
          <span className="font-bold">
            To ensure continuity of care, please complete the following survey (~1 minute)
          </span>{' '}
          so we can provide you with the right medication as you progress toward your weight loss
          goals.
        </span>
      </div>
      <Common.Button
        className="py-6 max-md:mt-auto md:self-center"
        color="blue"
        fullWidthOnMobile
        onClick={onContinue}
      >
        Go to survey
      </Common.Button>
    </div>
  );
};

export default Intro;
