import { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';

import { useDeleteDocumentMutation } from 'services/documents/documents';

import FileZone from 'features/FileZone';
import { DocsInfo } from 'features/FileZone/fileZone.types';
import Loader from 'shared/Loader';
import { notifyError } from 'shared/Toast/Toast';

import { useUploadDocument } from 'hooks/useUploadDocument';

// import { ERRORS_FOR_NOTIFICATIONS_FROM_VOUCHED } from 'utils/constants';
import { DocumentItem } from 'models/document.types';

import { PersonPhotoProps } from './personPhoto.types';

export const PersonPhoto: React.FC<PersonPhotoProps> = ({
  onFileStatusUpdate,
  onFileUpdate,
  initialValue,
  governmentID,
  verifyIdentity,
  accessToken
}) => {
  const [useAsAvatar, toggleUseAsAvatar] = useToggle(true);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<DocsInfo['personPhoto']>({
    _id: undefined,
    fileName: '',
    filePath: '',
    fileStatus: 'initial',
    identity: null
  });

  const [deleteDocument, { isLoading: deleteDocumentsLoading }] = useDeleteDocumentMutation();
  const { isLoading, uploadFile } = useUploadDocument();

  const verifyIdentityWithPortrait = (files: File[]) => {
    const images = files?.filter((item) =>
      ['image/png', 'image/jpg', 'image/jpeg', 'image/heic'].includes(item.type)
    );
    setCurrentFile(images[0]);
    onFileStatusUpdate();
  };

  const handleUploadThen = (data: DocumentItem, files: File[]) => {
    if (!!verifyIdentity) {
      verifyIdentity?.(governmentID, data._id, () => verifyIdentityWithPortrait(files));
    } else {
      setFileData((prev) => ({
        ...prev,
        ...data,
        fileStatus: 'success'
      }));
      onFileUpdate?.(files[0]);
      onFileStatusUpdate();
    }
  };

  const handleUpload = async (files: File[]) => {
    if (fileData._id) {
      await deleteDocument({ documentId: fileData._id, accessToken }).unwrap();
    }
    setFileData((prev) => ({
      ...prev,
      fileStatus: 'processing'
    }));
    const { data, error } = await uploadFile(files, {
      category: 'user-identity',
      subCategory: 'self-portrait'
    });
    if (error !== null) {
      setFileData((prev) => ({
        ...prev,
        fileStatus: 'initial'
      }));
      notifyError(error);
      onFileStatusUpdate();
    } else {
      handleUploadThen(data, files);
    }
  };

  const handleDelete = (id: string) => {
    deleteDocument({ documentId: id, accessToken })
      .unwrap()
      .then(() => {
        onFileUpdate?.(null);
        onFileStatusUpdate();
      });
  };

  const handleToggle = () => {
    Cookies.set('use-doc-as-avatar', !useAsAvatar ? '1' : '0');
    toggleUseAsAvatar();
  };

  const personPhotoErrors = fileData?.identity?.errors || [];

  useEffect(() => {
    onFileUpdate?.(useAsAvatar ? currentFile : null);
  }, [currentFile, useAsAvatar]);

  useEffect(() => {
    setFileData({
      ...initialValue,
      ...(!!accessToken && { fileStatus: !!initialValue._id ? 'success' : 'initial' })
    });
  }, [initialValue]);

  useEffect(() => {
    const isAgreedToUseAsAvatar = Cookies.get('use-doc-as-avatar');
    toggleUseAsAvatar(isAgreedToUseAsAvatar !== '0');
  }, []);

  return (
    <>
      <Loader isVisible={deleteDocumentsLoading} />
      <Common.Icon
        className="size-20 rounded-full text-primary-400 max-md:bg-primary-50 max-md:p-4 md:size-12"
        name="person-capture-photo"
      />
      <h2 className="verify-profile-heading">2. Upload a photo of yourself</h2>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1 text-gray">
          <Common.Icon className="size-4 flex-none" name="arrow-alt-right" />
          <p className="text-mSm">Your face must be well-lit and not blurry.</p>
        </div>
        <div className="flex items-center gap-1 text-gray">
          <Common.Icon className="size-4 flex-none" name="arrow-alt-right" />
          <p className="text-mSm">You must be the only person in the photo.</p>
        </div>
        <div className="flex items-center gap-1 text-gray">
          <Common.Icon className="size-4 flex-none" name="arrow-alt-right" />
          <p className="text-mSm">Nothing may cover your face, including sunglasses.</p>
        </div>
      </div>
      {!!onFileUpdate && (
        <Common.Checkbox checked={useAsAvatar} color="blue" size="sm" onChange={handleToggle}>
          Optional: Also use this for my profile photo
        </Common.Checkbox>
      )}
      <div className="w-full md:my-4 md:max-w-[280px]" data-testid="person_photo_id">
        <FileZone
          accept="image/png, image/jpeg, image/jpg, .heic"
          loading={isLoading || deleteDocumentsLoading}
          status={fileData}
          type="Add photo"
          onDelete={handleDelete}
          onSelect={handleUpload}
        />
      </div>
      {personPhotoErrors.map((error) => (
        <Common.Alert className="w-full" key={nanoid()} type="error" colorableBackground>
          <b>Error:</b> {error.message}
        </Common.Alert>
      ))}
    </>
  );
};
